import React from 'react'
import { Button } from 'react-bootstrap'
import { generateColorFromString } from '../helper'
import { IAdmin } from '../api/admin/adminType'
import { UserProfile } from 'oidc-client-ts'

const AvatarButton = ({
  user,
  onClick,
}: {
  user: UserProfile
  onClick: React.MouseEventHandler
}): React.ReactElement => (
  <Button variant='light' onClick={onClick} className='avatar-button'>
    <div
      className='avatar-icon me-2'
      style={{ backgroundColor: generateColorFromString(user?.preferred_username ?? 'member') }}
    >
      <span className='fs-5'>{user?.preferred_username?.slice(0, 1).toUpperCase()}</span>
    </div>
    <div className='avatar-detail'>
      <span className='fs-6 fw-bold'>{user?.given_name}</span>
      <span className='fs-7 text-muted'>{user?.preferred_username}</span>
      {/* <span className='fs-7 text-muted'>100 credit (hardcode)</span> */}
    </div>
  </Button>
)

export default AvatarButton
