import React from 'react'
import useUserStore from '../../stores/userStore'
import { IUserBalanceResponse } from '../../api/assets_managment/assetsManagmentType'
import { Table } from 'react-bootstrap'
import { formatter } from '../../helper'

const UserBalanceTable = (props: { list: IUserBalanceResponse[] }) => {
  const { list } = props

  const { user } = useUserStore()

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Email</th>
          <th>Fullname</th>
          <th>Wallet Address</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td>{item.email}</td>
            <td>{item.fullname}</td>
            <td>{item.walletAddress}</td>
            <td>{formatter(Number(item.balance))}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default UserBalanceTable
