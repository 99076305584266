import { IUser } from '../../interfaces'
import { IUserResponse } from '../user/userType'

export interface IPostBalanceTransactionBody {
  senderUserId?: any
  receiverUserId?: any
  assetId?: string
  amount: string
  type: string
  tokenType?: string
  status: string
  notes: string
}

export enum BalanceTransactionTypeEnum {
  TRANSFER = 'TRANSFER',
  WITHDRAW = 'WITHDRAWAL',
}

export const TransactionTypeOption = [
  {
    label: 'Transfer',
    value: BalanceTransactionTypeEnum.TRANSFER,
  },
  {
    label: 'Withdraw',
    value: BalanceTransactionTypeEnum.WITHDRAW,
  },
]

export enum BalanceTransactionTokenTypeEnum {
  ASSET = 'ASSET',
  CURRENCY = 'CURRENCY',
}

export const TokenTypeOption = [
  {
    label: 'Asset',
    value: BalanceTransactionTokenTypeEnum.ASSET,
  },
  {
    label: 'Currency',
    value: BalanceTransactionTokenTypeEnum.CURRENCY,
  },
]

export interface IBalanceTransactionResponse {
  amount: string
  approvedBy?: IUserResponse
  approvedById?: string
  assetId: string
  comment: string
  createdAt: string
  createdBy: IUserResponse
  createdById: string
  id: string
  notes: string
  organization: IUser
  organizationId: string
  receiverUser?: IUserResponse
  receiverUserId?: string
  receiverWalletAddress: string
  senderUser: IUserResponse
  senderUserId: string
  status: string
  tokenType: BalanceTransactionTokenTypeEnum
  transactionHash: string
  type: BalanceTransactionTypeEnum
  updatedAt: string
  updatedBy: IUserResponse
  updatedById: string
}
