import React, { useState } from 'react'
import usePagination from '../hooks/usePagination'
import { Button, Card, Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { HiPlus } from 'react-icons/hi'
import NoData from '../components/NoData'
import {
  getCurrencyBalance,
  getOrganizationBalance,
  getAssetById,
} from '../api/assets_managment/assetsManagement'
import {
  ContractStatus,
  IAssetResponse,
  IContractResponse,
  IUserBalanceResponse,
} from '../api/assets_managment/assetsManagmentType'
import { useParams } from 'react-router-dom'
import AssetContractsManagmentTable from '../features/AssetContracts/AssetContractsTable'
import UserBalanceTable from '../features/AssetContracts/UserBalanceTable'
import ContractCreateModal, {
  ContractModalType,
} from '../features/AssetContracts/ContractCreateModal'
import ContractDetailModal from '../features/AssetContracts/ContractDetailModal'
import ContractUpdateModal from '../features/AssetContracts/ContractUpdateModal'
import ContractApproveRejectModal from '../features/AssetContracts/ContractApproveRejectModal'
import ContractTransactionsModal from '../features/AssetContracts/ContractTransactionsModal'
import ContractResubmitTransactionsModal from '../features/AssetContracts/ContractResubmitTransactionsModal'
import ContractApproveRejectTransactionsModal from '../features/AssetContracts/ContractApproveRejectTransactionModal'
import ContractTransactionsDetailModal from '../features/AssetContracts/ContractTransactionsDetailModal'
import AssetContractHeadCard from '../features/AssetContracts/AssetContractHeadCard'
import ContractBalanceTransaction from '../features/AssetContracts/ContractCreateBalanceTransaction'
import BalanceTransactionTable from '../features/AssetContracts/TransactionsTable'
import { IBalanceTransactionResponse } from '../api/transactions/transactionType'
import ContractViewBalanceTransaction from '../features/AssetContracts/ContractDetailBalanceTransaction'
import ContractUpdateBalanceTransaction from '../features/AssetContracts/ContractUpdateBalanceTransaction'
import ContractApproveRejectBalanceTransaction from '../features/AssetContracts/ContractApproveRejectBalanceTransaction'

const AssetContractsPage = () => {
  const [selectedContract, setSelectedContract] = useState<IContractResponse>()
  const [modalShow, setModalShow] = React.useState(false)
  const [contractModalType, setContractModalType] = useState<ContractModalType>('CREATE')
  const [modalApproveRejectTransactionShow, setModalApproveRejectTransactionShow] =
    React.useState(false)
  const [modalTransactionDetailShow, setModalTransactionDetailShow] = React.useState(false)
  const [modalTransactionShow, setModalTransactionShow] = React.useState(false)
  const [modalResubmitTransactionShow, setModalResubmitTransactionShow] = React.useState(false)
  const [modalBalanceTransactionShow, setModalBalanceTransactionShow] = useState(false)
  const [modalDetailBalanceTransactionShow, setModalDetailBalanceTransactionShow] = useState(false)
  const [modalUpdateBalanceTransactionShow, setModalUpdateBalanceTransactionShow] = useState(false)
  const [modalApproveRejectBalanceTransactionShow, setModalApproveRejectBalanceTransactionShow] =
    useState(false)
  const [assetData, setAssetData] = useState<IAssetResponse>()
  const [key, setKey] = useState<any>('contracts')
  const [currencyFetchEnable, setCurrencyFetchEnable] = useState(false)
  const [assetBalance, setAssetBalance] = useState(0)
  const [currencyBalance, setCurrencyBalance] = useState(0)
  const [selectedTransferData, setSelectedTransferData] = useState<IBalanceTransactionResponse>()

  // params
  const params = useParams()
  const tokenId = params.tokenid

  // contracts data
  const { data, isLoading, renderPagination, refetch } = usePagination<IContractResponse>({
    queryKey: ['getContractsData', assetData],
    url: '/contracts',
    enable: assetData?.isImportedToken == false,
    handlePageParams: true,
    queryParams: {
      assetId: tokenId || '',
    },
  })

  // user balance asset data
  const {
    data: userBalanceData,
    isLoading: userBalanceLoading,
    renderPagination: userBalanceRenderPagination,
    refetch: userBalanceRefetch,
    handlePageChange: userBalanceHandlePageChange,
  } = usePagination<IUserBalanceResponse>({
    queryKey: ['getUserBalanceData'],
    url: `/assets/${tokenId}/balances`,
    handlePageParams: false,
  })

  // user balance currency data
  const {
    data: userCurrencyBalanceData,
    isLoading: userCurrencyBalanceLoading,
    renderPagination: userCurrencyBalanceRenderPagination,
    refetch: userCurrencyBalanceRefetch,
    handlePageChange: userCurrencyBalanceHandlePageChange,
  } = usePagination<IUserBalanceResponse>({
    queryKey: ['getUserCurrencyBalanceData', assetData],
    url: `/assets/${assetData?.currencyAssetId || ''}/balances`,
    handlePageParams: false,
    enable: currencyFetchEnable,
  })

  // user balance currency data
  const {
    data: userCurrencyTransactionsData,
    isLoading: userCurrencyTransactionsLoading,
    renderPagination: userCurrencyTransactionsRenderPagination,
    refetch: userCurrencyTransactionsRefetch,
    handlePageChange: userCurrencyTransactionsHandlePageChange,
  } = usePagination<IBalanceTransactionResponse>({
    queryKey: ['getUserCurrencyTransactionsData', assetData],
    url: '/transfers',
    handlePageParams: false,
    enable: currencyFetchEnable,
    queryParams: {
      assetId: assetData?.id || null,
    },
  })

  // fetch initial asset data
  const handleGetAssetData = async () => {
    try {
      if (!tokenId) return
      const res = await getAssetById(tokenId)
      if (res.data.isImportedToken) {
        setKey('transferbalance')
      }
      setAssetData(res.data)
    } catch (error) {
      //
    }
  }

  // fetch balance
  const handleGetAssetBalance = async () => {
    try {
      if (!assetData) return
      if (!assetData.currencyAssetId) return
      const _currencyBalance = await getCurrencyBalance(assetData.currencyAssetId)
      const _organizationBalance = await getOrganizationBalance(assetData.id)
      setCurrencyBalance(Number(_currencyBalance.data.balance))
      setAssetBalance(Number(_organizationBalance.data.balance))
    } catch (error) {
      //
    }
  }

  // show modal for edit by contract status
  const handleUpdateContract = (contract: IContractResponse) => {
    setSelectedContract(contract)
    setModalShow(true)
    if (contract.status === ContractStatus.PENDING) {
      setContractModalType('APPROVAL')
    } else {
      setContractModalType('UPDATE')
    }
  }

  // use effect
  React.useEffect(() => {
    handleGetAssetData()
  }, [tokenId])
  React.useEffect(() => {
    if (assetData) {
      setCurrencyFetchEnable(true)
      handleGetAssetBalance()
    }
  }, [assetData])

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Asset Contracts</h5>
        </Col>
      </Row>
      <Row className='g-0 mb-3'>
        <Col>
          <AssetContractHeadCard
            currencybalance={currencyBalance}
            organizationBalance={assetBalance}
            assetdata={assetData}
          />
        </Col>
      </Row>

      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => {
          refetch()
          setKey(k)
        }}
        className='mb-3'
      >
        {!assetData?.isImportedToken && (
          <Tab eventKey='contracts' title='Contracts'>
            <Row className='g-0'>
              <Col>
                <Card border='light' className='card-shadow'>
                  <Card.Body>
                    <>
                      <Row>
                        <Col className='d-flex align-items-center'>
                          <h6 className='m-0'>List</h6>
                        </Col>
                        <Col>
                          <Button
                            variant='primary'
                            className='float-end d-flex align-items-center'
                            onClick={(e) => {
                              e.preventDefault()
                              setModalShow(true)
                              setContractModalType('CREATE')
                            }}
                          >
                            <span className='d-inline-flex align-items-center'>
                              <HiPlus />
                            </span>
                            &nbsp; New Contract
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      {isLoading ? (
                        <div className='d-flex justify-content-center align-items-center p-5'>
                          <Spinner animation='border' variant='primary' />
                        </div>
                      ) : (
                        <>
                          {data && data.length > 0 ? (
                            <>
                              <AssetContractsManagmentTable
                                assetdata={assetData}
                                list={data}
                                onUpdateClick={(contract) => {
                                  handleUpdateContract(contract)
                                }}
                                onUpdateTransactionClick={(contract) => {
                                  setModalApproveRejectTransactionShow(true)
                                  setSelectedContract(contract)
                                }}
                                onViewClick={(contract) => {
                                  setContractModalType('VIEW')
                                  setModalShow(true)
                                  setSelectedContract(contract)
                                }}
                                onTransactionClick={(contract) => {
                                  setModalTransactionShow(true)
                                  setSelectedContract(contract)
                                }}
                                onResubmitClick={(contract) => {
                                  setModalResubmitTransactionShow(true)
                                  setSelectedContract(contract)
                                }}
                                onTransactionDetailClick={(contract) => {
                                  setModalTransactionDetailShow(true)
                                  setSelectedContract(contract)
                                }}
                              />
                              <div className='d-flex justify-content-end align-items-center'>
                                {renderPagination()}
                              </div>
                            </>
                          ) : (
                            <NoData />
                          )}
                        </>
                      )}
                    </>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Tab>
        )}

        <Tab eventKey='userbalance' title='Asset Balance'>
          {userBalanceLoading ? (
            <div className='d-flex justify-content-center align-items-center p-5'>
              <Spinner animation='border' variant='primary' />
            </div>
          ) : (
            <Row className='g-0'>
              <Col>
                <Card border='light' className='card-shadow'>
                  <Card.Body>
                    <>
                      {userBalanceData && userBalanceData.length > 0 ? (
                        <>
                          <UserBalanceTable list={userBalanceData} />
                          <div className='d-flex justify-content-end align-items-center'>
                            {userBalanceRenderPagination()}
                          </div>
                        </>
                      ) : (
                        <NoData />
                      )}
                    </>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Tab>
        <Tab eventKey='currencybalance' title='Currency Balance'>
          {userCurrencyBalanceLoading ? (
            <div className='d-flex justify-content-center align-items-center p-5'>
              <Spinner animation='border' variant='primary' />
            </div>
          ) : (
            <Row className='g-0'>
              <Col>
                <Card border='light' className='card-shadow'>
                  <Card.Body>
                    <>
                      {userCurrencyBalanceData && userCurrencyBalanceData.length > 0 ? (
                        <>
                          <UserBalanceTable list={userCurrencyBalanceData} />
                          <div className='d-flex justify-content-end align-items-center'>
                            {userCurrencyBalanceRenderPagination()}
                          </div>
                        </>
                      ) : (
                        <NoData />
                      )}
                    </>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Tab>
        <Tab eventKey='transferbalance' title='Transactions'>
          {userCurrencyBalanceLoading ? (
            <div className='d-flex justify-content-center align-items-center p-5'>
              <Spinner animation='border' variant='primary' />
            </div>
          ) : (
            <Row className='g-0'>
              <Col>
                <Card border='light' className='card-shadow'>
                  <Card.Body>
                    <>
                      <Row>
                        <Col className='d-flex align-items-center'>
                          <h6 className='m-0'>List</h6>
                        </Col>
                        <Col>
                          <Button
                            variant='primary'
                            className='float-end d-flex align-items-center'
                            onClick={(e) => {
                              e.preventDefault()
                              setModalBalanceTransactionShow(true)
                            }}
                          >
                            <span className='d-inline-flex align-items-center'>
                              <HiPlus />
                            </span>
                            &nbsp; New Transaction
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      {userCurrencyTransactionsData && userCurrencyTransactionsData.length > 0 ? (
                        <>
                          <BalanceTransactionTable
                            handleApprove={(data) => {
                              setSelectedTransferData(data)
                              setModalApproveRejectBalanceTransactionShow(true)
                            }}
                            handleEdit={(data) => {
                              setSelectedTransferData(data)
                              setModalUpdateBalanceTransactionShow(true)
                            }}
                            handleViewDetail={(data) => {
                              setSelectedTransferData(data)
                              setModalDetailBalanceTransactionShow(true)
                            }}
                            list={userCurrencyTransactionsData}
                          />
                          <div className='d-flex justify-content-end align-items-center'>
                            {userCurrencyTransactionsRenderPagination()}
                          </div>
                        </>
                      ) : (
                        <NoData />
                      )}
                    </>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Tab>
      </Tabs>

      <ContractCreateModal
        type={contractModalType}
        show={modalShow}
        assetdata={assetData}
        selectedcontract={selectedContract}
        onHide={() => {
          setModalShow(false)
          setSelectedContract(undefined)
          refetch()
        }}
      />
      <ContractTransactionsModal
        show={modalTransactionShow}
        selectedcontract={selectedContract}
        onHide={() => {
          setModalTransactionShow(false)
          setSelectedContract(undefined)
          refetch()
        }}
      />
      <ContractResubmitTransactionsModal
        show={modalResubmitTransactionShow}
        selectedcontract={selectedContract}
        onHide={() => {
          setModalResubmitTransactionShow(false)
          setSelectedContract(undefined)
          refetch()
        }}
      />
      <ContractApproveRejectTransactionsModal
        show={modalApproveRejectTransactionShow}
        selectedcontract={selectedContract}
        onHide={() => {
          setModalApproveRejectTransactionShow(false)
          refetch()
          setSelectedContract(undefined)
        }}
      />
      <ContractTransactionsDetailModal
        show={modalTransactionDetailShow}
        selectedcontract={selectedContract}
        onHide={() => {
          setModalTransactionDetailShow(false)
          setSelectedContract(undefined)
        }}
      />
      <ContractBalanceTransaction
        show={modalBalanceTransactionShow}
        assetdata={assetData}
        onHide={() => {
          setModalBalanceTransactionShow(false)
          setSelectedTransferData(undefined)
          userCurrencyTransactionsRefetch()
        }}
      />
      <ContractViewBalanceTransaction
        show={modalDetailBalanceTransactionShow}
        assetdata={assetData}
        selectedData={selectedTransferData}
        onHide={() => {
          setModalDetailBalanceTransactionShow(false)
          setSelectedTransferData(undefined)
          userCurrencyTransactionsRefetch()
        }}
      />
      <ContractUpdateBalanceTransaction
        show={modalUpdateBalanceTransactionShow}
        assetdata={assetData}
        selectedData={selectedTransferData}
        onHide={() => {
          setModalUpdateBalanceTransactionShow(false)
          setSelectedTransferData(undefined)
          userCurrencyTransactionsRefetch()
        }}
      />
      <ContractApproveRejectBalanceTransaction
        show={modalApproveRejectBalanceTransactionShow}
        assetdata={assetData}
        selectedData={selectedTransferData}
        onHide={() => {
          setModalApproveRejectBalanceTransactionShow(false)
          setSelectedTransferData(undefined)
          userCurrencyTransactionsRefetch()
        }}
      />
    </>
  )
}

export default AssetContractsPage
