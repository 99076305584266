import { UserManager, UserManagerSettings, WebStorageStateStore, User } from 'oidc-client-ts'
import { useEffect, useState } from 'react'
import zitadelConfig from '../zitadelConfig'
import useUserStore from '../stores/userStore'
import { setToLocalStorage } from '../stores/local_storage'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { getAdminDataByExternalId } from '../api/user/user'

const useZitadel = () => {
  const [userManager, setUserManager] = useState<UserManager>()
  const [authenticated, setAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState<User>()
  const { setUser } = useUserStore()
  const navigate = useNavigate()

  useEffect(() => {
    const manager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      ...zitadelConfig,
    })

    setUserManager(manager)
  }, [])

  useEffect(() => {
    if (userManager) {
      if (window.location.href.includes('id_token') || window.location.href.includes('code')) {
        userManager
          .signinRedirectCallback()
          .then(async (user) => {
            if (user) {
              setAuthenticated(true)
              setUserInfo(user) // Store the entire user object
              setUser(user.profile)
              setToLocalStorage({ token: user.access_token })
              setToLocalStorage({ refreshToken: user.refresh_token })
              setToLocalStorage({ user: user.profile })
              navigate('/asset-management')
              toast.success('Login Successfully')
            }
          })
          .catch((error) => {
            console.error('Sign-in error', error)
          })
      }
    }
  }, [userManager])

  const authorize = () => {
    userManager && userManager.signinRedirect({ state: 'a2123a67ff11413fa19217a9ea0fbad5' })
  }

  const clearAuth = () => {
    userManager && userManager.signoutRedirect()
  }

  return {
    authorize,
    clearAuth,
    authenticated,
    userInfo,
  }
}

export default useZitadel
