import * as yup from 'yup'
import {
  ContractTypeEnum,
  IContractResponse,
  ITransactionResponse,
} from '../../api/assets_managment/assetsManagmentType'

export const assetContractSchema = yup
  .object({
    userIds: yup
      .mixed()
      .optional()
      .test('user-ids-test', 'Users is required field', (val, ctx) => {
        console.log(val)
        const type: ContractTypeEnum = ctx.parent.type
        return type === ContractTypeEnum.ONCHAIN_SWAP || type === ContractTypeEnum.SWAP
          ? true
          : Array.isArray(val) && val.length > 0
      }),
    assetId: yup.string().optional(),
    amount: yup.string().required(),
    type: yup.string().required(),
    status: yup.string().required(),
    distributionDate: yup.string().optional(),
    condition: yup.string().required(),
    cashflowDetail: yup.string().required(),
    assetAmount: yup
      .string()
      .optional()
      .test('asset-amount-test', 'Quantity is required field', (val, ctx) => {
        const type: ContractTypeEnum = ctx.parent.type
        return type === ContractTypeEnum.ONCHAIN_SWAP || type === ContractTypeEnum.SWAP
          ? val && val !== ''
            ? true
            : false
          : true
      }),
    buyerUserId: yup
      .mixed()
      .optional()
      .test('buyer-id-test', 'Payer is required field', (val, ctx) => {
        const type: ContractTypeEnum = ctx.parent.type
        return type === ContractTypeEnum.ONCHAIN_SWAP || type === ContractTypeEnum.SWAP
          ? val && val !== ''
          : true
      }),
    sellerUserId: yup
      .mixed()
      .optional()
      .test('seller-id-test', 'Payee is required field', (val, ctx) => {
        const type: ContractTypeEnum = ctx.parent.type
        return type === ContractTypeEnum.ONCHAIN_SWAP || type === ContractTypeEnum.SWAP
          ? val && val !== ''
          : true
      }),
  })
  .required()

export const balanceTransferSchema = yup
  .object({
    receiverUserId: yup.mixed().optional(),
    senderUserId: yup.mixed().optional(),
    assetId: yup.string().optional(),
    amount: yup.string().required(),
    tokenType: yup.string().optional(),
    type: yup.string().required(),
    status: yup.string().required(),
    notes: yup.string().required(),
  })
  .required()

export const roleoptions = [
  {
    label: 'Payer',
    value: 'Payer',
  },
  {
    label: 'Payee',
    value: 'Payee',
  },
]

export interface ITransactionTable {
  transactiondata: ITransactionResponse[]
  handleGetTransaction: (page: number) => void
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  page?: number
  handlePageChange?: (page: number) => void
  totalpage?: number
  contractdata?: IContractResponse
}

export const TRANSACTIONS_PAGE_SIZE = 10
