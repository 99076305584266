import React from 'react'
import { currencyStringToNumber, debounce } from '../../../helper'
import { ITransactionResponse } from '../../../api/assets_managment/assetsManagmentType'
import { MaskedInputProps } from 'react-text-mask'
import { putTransaction } from '../../../api/assets_managment/assetsManagement'
import CurrencyInput from '../../../components/Input/CurrencyInput'

const AmountInput: React.FC<{
  transaction: ITransactionResponse
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  refetch: () => void
  disabled?: boolean
  price?: number
}> = ({ transaction, isLoading, setIsLoading, refetch, disabled, price }) => {
  const [value, setValue] = React.useState<string>()
  const onChangeRef = React.useRef<HTMLInputElement & { props: MaskedInputProps }>(null)
  // useref
  const handleChangeDebounce = React.useRef(
    debounce((handleChangeAmount) => {
      handleChangeAmount()
    }, 1000),
  )

  React.useEffect(() => {
    if (transaction) {
      setValue(transaction.amount)
    }
  }, [transaction])

  // handle search
  const handleChangeAmount = async () => {
    try {
      setIsLoading(true)
      if (onChangeRef.current && transaction) {
        const _amount = onChangeRef.current.props.value
        if (_amount !== null && _amount !== undefined) {
          await putTransaction(transaction.id, { amount: String(_amount) })
          refetch()
        }
      }
    } catch (error) {
      //
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <CurrencyInput
        className='form-control'
        disabled={disabled ? disabled : isLoading}
        ref={onChangeRef}
        defaultValue=''
        value={price ? price : value && Number(value)}
        onChange={(e) => {
          setValue(currencyStringToNumber(e.target.value))
          handleChangeDebounce.current(handleChangeAmount)
        }}
        type='text'
        placeholder='Enter amount'
      />
    </>
  )
}

export default AmountInput
