import React, { useState } from 'react'
import usePagination from '../hooks/usePagination'
import { Button, Card, Col, Form, Image, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { IAdmin } from '../api/admin/adminType'
import { HiPlus } from 'react-icons/hi'
import NoData from '../components/NoData'
import { FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { formatter, handleAxiosError } from '../helper'
import checked from '../assets/images/checked.png'
import * as yup from 'yup'
import { postNewAsset } from '../api/assets_managment/assetsManagement'
import {
  AssetType,
  IPostAssetBody,
  IPostCurrencyAsset,
  IPostLoanAsset,
} from '../api/assets_managment/assetsManagmentType'
import AssetTypeSelect from '../components/Select/AssetTypeSelect'
import CurrencyInput from '../components/Input/CurrencyInput'

const assetSchema = yup
  .object({
    price: yup.string().required(),
    currency: yup.string().required(),
    name: yup.string().required(),
    type: yup.string().required(),
    quantity: yup.string().required(),
    custodian: yup.string().required(),
    asset_detail: yup.string().required(),
    decimal: yup.number().optional(),
  })
  .required()

const EditBalanceModal = (props: {
  show: boolean
  onHide: () => void
  setmodalshow: (value: boolean) => void
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, setmodalshow: setModalShow } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(assetSchema),
  })

  const onSubmit = async (data: any) => {
    setIsLoading(true)

    try {
      const response = await postNewAsset(data)
      if (response.status === 201) {
        setIsComplete(true)
        reset()
      }
    } catch (error) {
      handleAxiosError(error)
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Edit Balance</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Balance updated successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='price'>
              <Form.Label>Current Balance</Form.Label>
              <CurrencyInput
                className='form-control'
                disabled={true}
                value={100}
                type='text'
                placeholder='Enter price'
                {...register('price', { required: true })}
                onChange={(e) => {
                  setValue('price', e.target.value)
                }}
              />

              <Form.Text className='text-danger'>{errors.name?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='price'>
              <Form.Label>Amount</Form.Label>
              <CurrencyInput
                className='form-control'
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter amount'
                {...register('price', { required: true })}
                onChange={(e) => {
                  setValue('price', e.target.value)
                }}
              />

              <Form.Text className='text-danger'>{errors.name?.message?.toString()}</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Deduct
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Topup
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const AssetManagmentTable = (props: { list: IAdmin[]; onUpdateClick: () => void }) => {
  const { onUpdateClick } = props

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Organization</th>
          <th>Credit Balance</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {[0, 1, 2, 3].map((item, index) => (
          <tr key={index}>
            <td>Seaseed{index + 1}</td>
            <td>100</td>
            <td>
              <div className='d-flex gap-2 w-auto'>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onUpdateClick()
                  }}
                >
                  Edit Balance
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const CreditTopup = () => {
  const [modalEditShow, setModalEditShow] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IAdmin>()
  const [modalShow, setModalShow] = React.useState(false)
  //   const { data, isLoading, renderPagination, refetch } = usePagination<IAdmin>({
  //     queryKey: ['getAssets'],
  //     url: '/adminss',
  //     handlePageParams: true,
  //   })

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Credit Topup</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>List</h6>
                  </Col>
                  <Col></Col>
                </Row>
                <hr />
                {/* {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? ( */}
                <>
                  <AssetManagmentTable
                    list={[]}
                    onUpdateClick={() => {
                      setModalShow(true)
                      //   setSelectedUser(user)
                    }}
                  />
                  <div className='d-flex justify-content-end align-items-center'>
                    {/* {renderPagination()} */}
                  </div>
                </>
                {/* ) : (
                      <NoData />
                    )}
                  </>
                )} */}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <EditBalanceModal
        show={modalShow}
        setmodalshow={(value: boolean) => setModalShow(value)}
        onHide={() => {
          setModalShow(false)
          //   refetch()
        }}
      />
    </>
  )
}

export default CreditTopup
