import React, { useState } from 'react'
import usePagination from '../hooks/usePagination'
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap'
import { HiPlus } from 'react-icons/hi'
import NoData from '../components/NoData'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { currencyStringToNumber, formatter, handleAxiosError } from '../helper'
import checked from '../assets/images/checked.png'
import * as yup from 'yup'
import {
  postApproveAsset,
  postNewAsset,
  postRejectAsset,
  putNewAsset,
} from '../api/assets_managment/assetsManagement'
import {
  AssetType,
  IPostAssetBody,
  PostAssetStatus,
  IAssetResponse,
  AssetStatus,
  CreationTypeOption,
  CreationType,
  AssetTypeOption,
} from '../api/assets_managment/assetsManagmentType'
import AssetTypeSelect from '../components/Select/AssetTypeSelect'
import CurrencyInput from '../components/Input/CurrencyInput'
import UserSelect from '../components/Select/UserSelect'
import { IUserResponse } from '../api/user/userType'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../stores/userStore'
import Select, { SingleValue, useStateManager } from 'react-select'
import { BiSolidInfoCircle } from 'react-icons/bi'

const assetSchema = yup
  .object({
    price: yup.string().optional().nullable(),
    name: yup.string().optional().nullable(),
    type: yup.mixed<AssetType>().oneOf(Object.values(AssetType)).required(),
    symbol: yup.string().optional().nullable(),
    currencyAssetId: yup
      .string()
      .optional()
      .nullable()
      .test('check-decimal', 'currency asset is required field', (value, ctx) => {
        const type: AssetType = ctx.parent.type

        return type === AssetType.LOAN ? value !== undefined : true
      }),
    maxSupply: yup.string().optional().nullable(),
    custodianId: yup.string().required('custodian is required field'),
    decimalPlaces: yup
      .number()
      .typeError('decimal places must be a number')
      .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
      .optional()
      .nullable()
      .test('check-decimal', 'decimal places is required', (value, ctx) => {
        const type: AssetType = ctx.parent.type

        return type === AssetType.CURRENCY ? value !== undefined : true
      }),
    status: yup.mixed<PostAssetStatus>().oneOf(Object.values(PostAssetStatus)).required(),
    detailAsset: yup.string().required(),
    isImportedToken: yup.boolean().required(),
    smartContractAddress: yup
      .string()
      .optional()
      .nullable()
      .test('check-contract-adress', 'contract address is required', (value, ctx) => {
        const isImportedToken: boolean = ctx.parent.isImportedToken
        return isImportedToken ? value !== undefined : true
      }),
  })
  .required()

type ModalType = 'CREATION' | 'UPDATE' | 'APPROVAL' | 'VIEW'

const AssetCreateModal = (props: {
  show: boolean
  onHide: () => void
  type: ModalType
  selectedasset?: IAssetResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)
  const [comment, setComment] = useState<string>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedasset, type } = props

  const IS_NOT_VIEW_MODAL = type !== 'APPROVAL' && type !== 'VIEW'

  const { data: users } = usePagination<IUserResponse>({
    queryKey: ['getUsersWallet'],
    url: '/users',
    handlePageParams: false,
    pageSize: 99,
    enable: IS_NOT_VIEW_MODAL,
    mapDataFn: (users) =>
      users.map((user: IUserResponse) => ({
        ...user,
        value: user.id,
        label: user.fullname,
      })),
  })

  const { data: currencyAssets } = usePagination<IAssetResponse>({
    queryKey: ['getCurrencyAssets'],
    url: '/assets',
    handlePageParams: false,
    pageSize: 99,
    enable: IS_NOT_VIEW_MODAL,
    queryParams: {
      type: AssetType.CURRENCY,
      status: AssetStatus.APPROVED,
    },
    mapDataFn: (assets) =>
      assets.map((asset: IAssetResponse) => ({
        ...asset,
        value: asset.id,
        label: asset.name,
      })),
  })

  const selectedUser = selectedasset?.custodian && {
    ...selectedasset.custodian,
    label: selectedasset.custodian.fullname,
    value: selectedasset.custodian.id,
  }
  const selectedCurrency = selectedasset?.currencyAsset && {
    ...selectedasset.currencyAsset,
    label: selectedasset.currencyAsset.name,
    value: selectedasset.currencyAsset.id,
  }

  React.useEffect(() => {
    if (selectedasset) {
      setValue('type', selectedasset.type)
      setValue('name', selectedasset.name)
      setValue('symbol', selectedasset.symbol)
      setValue('custodianId', selectedasset.custodianId)
      setValue('decimalPlaces', selectedasset.decimalPlaces)
      setValue('detailAsset', selectedasset.detailAsset)
      setValue('price', selectedasset.price)
      setValue('currencyAssetId', selectedasset.currencyAssetId || undefined)
      setValue('maxSupply', selectedasset.maxSupply)
      setValue('isImportedToken', selectedasset.isImportedToken)
      setValue('smartContractAddress', selectedasset.smartContractAddress)
      setComment(selectedasset?.comment)
    }
  }, [selectedasset])

  const handleUpdate = async (data: IPostAssetBody) => {
    setIsLoading(true)

    try {
      if (!selectedasset) return
      const response = await putNewAsset(selectedasset.id, data)

      setIsComplete(true)
      reset()
    } catch (error) {
      handleAxiosError(error)
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitApproval = async (type: 'APPROVE' | 'REJECT') => {
    if (!selectedasset) return
    setIsLoading(true)
    try {
      if (type === 'APPROVE') {
        await postApproveAsset(selectedasset.id, { comment: comment })
      } else {
        await postRejectAsset(selectedasset.id, { comment: comment })
      }
      setIsComplete(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm<IPostAssetBody>({
    resolver: yupResolver(assetSchema),
    defaultValues: {
      type: AssetType.CURRENCY,
      isImportedToken: false,
    },
  })
  const onSubmit = async (data: IPostAssetBody) => {
    setIsLoading(true)

    try {
      const response = await postNewAsset({
        ...data,
        price: data.price,
      })
      setIsComplete(true)
      reset()
    } catch (error) {
      handleAxiosError(error)
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>
            {type === 'CREATION'
              ? 'New Asset'
              : type === 'UPDATE'
              ? 'Update Asset'
              : type === 'VIEW'
              ? 'Detail Asset'
              : 'Approve Asset'}
          </h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>
                Asset {type === 'CREATION' ? 'created' : type === 'UPDATE' ? 'updated' : 'updated'}{' '}
                successfully
              </h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form
          onSubmit={handleSubmit(
            type === 'CREATION' ? onSubmit : type === 'UPDATE' ? handleUpdate : () => null,
          )}
        >
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='name'>
              <AssetTypeSelect
                disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                value={watch('type') as AssetType}
                onChange={
                  IS_NOT_VIEW_MODAL ? (val: AssetType) => setValue('type', val) : () => null
                }
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='custodian'>
              <Form.Label>Creation Type</Form.Label>
              <Select
                options={CreationTypeOption}
                isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                value={
                  watch('isImportedToken')
                    ? CreationTypeOption.find((val) => val.value === CreationType.IMPORT)
                    : CreationTypeOption.find((val) => val.value === CreationType.NEW)
                }
                onChange={(option: any) => {
                  setValue('isImportedToken', option?.value === CreationType.IMPORT ? true : false)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.isImportedToken?.message?.toString()}
              </Form.Text>
            </Form.Group>

            {watch('isImportedToken') && (
              <Form.Group className='mb-3' controlId='currency'>
                <Form.Label>Contract Adress</Form.Label>
                <Form.Control
                  disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                  type='text'
                  placeholder='Enter contract address'
                  {...register('smartContractAddress', { required: false })}
                  onChange={(e) => {
                    setValue('smartContractAddress', e.target.value)
                  }}
                />
                <Form.Text className='text-danger'>
                  {errors.smartContractAddress?.message?.toString()}
                </Form.Text>
              </Form.Group>
            )}

            {!watch('isImportedToken') && (
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='currency'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                      type='text'
                      placeholder='Enter name'
                      {...register('name', { required: false })}
                      onChange={(e) => {
                        setValue('name', e.target.value)
                      }}
                    />
                    <Form.Text className='text-danger'>
                      {errors.name?.message?.toString()}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='symbol'>
                    <Form.Label>Symbol</Form.Label>
                    <Form.Control
                      className='form-control'
                      disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                      type='text'
                      placeholder='Enter symbol'
                      {...register('symbol', { required: false })}
                      onChange={(e) => {
                        setValue('symbol', e.target.value)
                      }}
                    />

                    <Form.Text className='text-danger'>
                      {errors.symbol?.message?.toString()}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {watch('type') === AssetType.LOAN && (
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='currency'>
                    <Form.Label>Currency</Form.Label>
                    <Select
                      options={currencyAssets}
                      defaultValue={selectedCurrency}
                      value={currencyAssets.find((type) => type.id === watch('currencyAssetId'))}
                      placeholder='Select currency'
                      isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                      onChange={(option: SingleValue<IAssetResponse> | any) => {
                        setValue('currencyAssetId', option?.value)
                      }}
                    />
                    <Form.Text className='text-danger'>
                      {errors.currencyAssetId?.message?.toString()}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='price'>
                    <Form.Label>Price</Form.Label>
                    <CurrencyInput
                      className='form-control'
                      disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                      type='text'
                      value={(watch('price') && Number(watch('price'))) || undefined}
                      placeholder='Enter price'
                      {...register('price', { required: false })}
                      onChange={(e) => {
                        setValue('price', currencyStringToNumber(e.target.value))
                      }}
                    />

                    <Form.Text className='text-danger'>
                      {errors.name?.message?.toString()}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            )}
            {!watch('isImportedToken') && watch('type') === AssetType.LOAN && (
              <Form.Group className='mb-3' controlId='quantity'>
                <Form.Label>Maximum Quantity</Form.Label>
                <CurrencyInput
                  className='form-control'
                  disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                  type='text'
                  placeholder='Enter quantity'
                  {...register('maxSupply', { required: false })}
                  value={(watch('maxSupply') && Number(watch('maxSupply'))) || undefined}
                  onChange={(e) => {
                    setValue('maxSupply', currencyStringToNumber(e.target.value))
                  }}
                />
                <Form.Text className='text-danger'>
                  {errors.maxSupply?.message?.toString()}
                </Form.Text>
              </Form.Group>
            )}

            {!watch('isImportedToken') && watch('type') === AssetType.CURRENCY && (
              <Form.Group className='mb-3' controlId='decimal'>
                <Form.Label>
                  No. of Decimal Places (e.g., 4 will be represented as 0.0001)
                </Form.Label>
                <Form.Control
                  disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                  type='number'
                  placeholder='Enter decimal'
                  {...register('decimalPlaces', { required: false })}
                  onChange={(e) => {
                    setValue('decimalPlaces', Number(e.target.value))
                  }}
                />
                <Form.Text className='text-danger'>
                  {errors.decimalPlaces?.message?.toString()}
                </Form.Text>
              </Form.Group>
            )}

            <Form.Group className='mb-3' controlId='custodian'>
              <Form.Label>Custodian</Form.Label>
              <UserSelect
                isSearchable={true}
                options={users}
                defaultValue={selectedUser}
                isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                onChange={(option: SingleValue<IUserResponse> | any) => {
                  setValue('custodianId', option?.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.custodianId?.message?.toString()}
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='username'>
              <Form.Label>Asset Detail</Form.Label>
              <Form.Control
                disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                as='textarea'
                type='text'
                placeholder='Enter Asset Detail...'
                {...register('detailAsset', { required: false })}
                onChange={(e) => {
                  setValue('detailAsset', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.detailAsset?.message?.toString()}
              </Form.Text>
            </Form.Group>
            {(type === 'APPROVAL' || type === 'VIEW') && (
              <Form.Group className='mb-3' controlId='username'>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  disabled={type === 'VIEW' ? true : isLoading}
                  as='textarea'
                  type='text'
                  placeholder='Enter comment'
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
                />
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              {type === 'VIEW' ? 'Close' : 'Cancel'}
            </Button>
            {(type === 'CREATION' || type === 'UPDATE') && (
              <>
                <Button
                  variant='primary'
                  type='submit'
                  onClick={() => {
                    setValue('status', PostAssetStatus.DRAFT)
                  }}
                  disabled={isLoading}
                >
                  Save as Draft
                </Button>
                <Button
                  variant='primary'
                  onClick={() => {
                    setValue('status', PostAssetStatus.PENDING)
                  }}
                  type='submit'
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </>
            )}

            {type === 'APPROVAL' && (
              <>
                <Button
                  variant='primary'
                  type='button'
                  onClick={() => handleSubmitApproval('REJECT')}
                  disabled={isLoading}
                >
                  Reject
                </Button>
                <Button
                  variant='primary'
                  onClick={() => handleSubmitApproval('APPROVE')}
                  type='button'
                  disabled={isLoading}
                >
                  Approve
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const AssetManagmentTable = (props: {
  list: IAssetResponse[]
  onUpdateClick: (data: IAssetResponse) => void
  onViewClick: (data: IAssetResponse) => void
}) => {
  const { onUpdateClick, onViewClick } = props
  const [showInfo, setShowInfo] = useState(false)

  const { user } = useUserStore()

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Symbol</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Type</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td>
              {item.name}
              {item.isImportedToken && (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='top'
                  overlay={
                    <Popover id='popover-trigger-hover-focus' className='p-2'>
                      Imported
                    </Popover>
                  }
                >
                  <div
                    className={'d-inline-block align-items-center'}
                    style={{ marginLeft: 5, cursor: 'pointer' }}
                  >
                    <BiSolidInfoCircle />
                  </div>
                </OverlayTrigger>
              )}
            </td>
            <td>{item.symbol}</td>
            <td>{(item.price && formatter(Number(item.price))) || '-'}</td>
            <td>
              {(item.maxSupply && new Intl.NumberFormat('us-EN').format(Number(item.maxSupply))) ||
                '-'}
            </td>
            <td>{item.type}</td>
            <td>{item.status}</td>
            <td>
              <div className='d-flex gap-2'>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onViewClick(item)
                  }}
                >
                  View
                </Button>
                {item.approvedById !== null && item.type === AssetType.CURRENCY ? null : (
                  <Button
                    size='sm'
                    variant={`${item.approvedById !== null ? 'success' : 'primary'}`}
                    className='float-end d-flex align-items-center'
                    disabled={
                      item.approvedById !== null
                        ? false
                        : item.status === AssetStatus.DRAFT
                        ? false
                        : item.updatedBy.externalId === user?.sub
                    }
                    onClick={(e) => {
                      onUpdateClick(item)
                    }}
                  >
                    {item.approvedById !== null
                      ? 'Contracts'
                      : item.status === AssetStatus.DRAFT
                      ? 'Edit'
                      : 'Approve'}
                  </Button>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const AssetManagementPage = () => {
  // const [modalEditShow, setModalEditShow] = useState(false)
  // const [modalDetailShow, setModalDetailShow] = useState(false)
  // const [modalApproveReject, setModalApproveReject] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState<IAssetResponse>()
  const [modalShow, setModalShow] = React.useState(false)
  const [modalType, setModalType] = useState<ModalType>('CREATION')
  const [filter, setFilter] = useState<string | null>(null)
  const { data, isLoading, renderPagination, refetch, handlePageChange } =
    usePagination<IAssetResponse>({
      queryKey: ['getAssets'],
      url: '/assets',
      handlePageParams: true,
      queryParams: {
        type: filter,
      },
    })

  const handleUpdateAsset = (asset: IAssetResponse) => {
    setSelectedAsset(asset)
    if (asset.status === AssetStatus.PENDING) {
      setModalType('APPROVAL')
    } else {
      setModalType('UPDATE')
    }
    setModalShow(true)
  }

  const navigate = useNavigate()

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Asset Management</h5>
        </Col>
      </Row>
      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>List</h6>
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Asset
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md='3'>
                    <Form.Group className='mb-3' controlId='custodian'>
                      <Form.Label>Asset Type</Form.Label>
                      <Select
                        options={[
                          {
                            label: 'All',
                            value: null,
                          },
                          ...AssetTypeOption,
                        ]}
                        onChange={(val: SingleValue<any>) => {
                          setFilter(val.value)
                          handlePageChange(1)
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <AssetManagmentTable
                          list={data}
                          onViewClick={(asset) => {
                            setSelectedAsset(asset)
                            setModalType('VIEW')
                            setModalShow(true)
                          }}
                          onUpdateClick={(asset) =>
                            asset.approvedById !== null
                              ? navigate(`/asset-management/${asset.id}`)
                              : handleUpdateAsset(asset)
                          }
                        />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AssetCreateModal
        selectedasset={selectedAsset}
        type={modalType}
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          setSelectedAsset(undefined)
          refetch()
        }}
      />
    </>
  )
}

export default AssetManagementPage
