import { IAdmin } from '../api/admin/adminType'
import { IPaymentProviderValue } from '../interfaces'

export interface GlobalStateType {
  user?: IAdmin
}

// DEFAULT VALUE GLOBAL STATE
export const globalState: GlobalStateType = {
  user: undefined,
}
