import React, { ComponentPropsWithoutRef } from 'react'
import { AssetType } from '../../api/assets_managment/assetsManagmentType'
import { object } from 'yup'

interface IAssetTypeSelect {
  value: AssetType
  onChange: (value: AssetType) => void
  disabled?: boolean
}

const AssetTypeSelect: React.FC<IAssetTypeSelect> = (props) => {
  const options = [
    {
      label: 'Loan',
      value: AssetType.LOAN,
    },
    {
      label: 'Currency',
      value: AssetType.CURRENCY,
    },
  ]
  return (
    <div className='asset-type-select'>
      <label htmlFor=''>Type</label>
      <ul className='asset-type-select__option'>
        {options.map((option, index) => {
          return (
            <li
              key={index}
              onClick={() => props.onChange(option.value)}
              className={`asset-type-select__option__item card ${
                option.value === props.value ? 'asset-type-select__option__item--selected' : ''
              } ${props.disabled ? 'asset-type-select__option__item--disabled' : ''} `}
            >
              {option.label}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default AssetTypeSelect
