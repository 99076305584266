import React from 'react'
import {
  ContractStatus,
  IAssetResponse,
  IContractResponse,
  IS_TRANSACTIONS_STATUS,
  getContractTypeLabel,
} from '../../api/assets_managment/assetsManagmentType'
import useUserStore from '../../stores/userStore'
import { Button, Table } from 'react-bootstrap'
import { formatter } from '../../helper'
import moment from 'moment'

const AssetContractsManagmentTable = (props: {
  list: IContractResponse[]
  onUpdateClick: (data: IContractResponse) => void
  onViewClick: (data: IContractResponse) => void
  onTransactionClick: (data: IContractResponse) => void
  onResubmitClick: (data: IContractResponse) => void
  onUpdateTransactionClick: (data: IContractResponse) => void
  onTransactionDetailClick: (data: IContractResponse) => void
  assetdata?: IAssetResponse
}) => {
  const {
    onUpdateClick,
    onViewClick,
    assetdata,
    onTransactionClick,
    onUpdateTransactionClick,
    onTransactionDetailClick,
    onResubmitClick,
  } = props

  const { user } = useUserStore()

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Contract Type</th>
          <th>Quantity ({assetdata?.symbol})</th>
          <th>Amount ({assetdata?.currencyAsset?.symbol})</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td style={{ minWidth: '200px' }}>{getContractTypeLabel(item.type)}</td>
            <td style={{ minWidth: '200px' }}>
              {(item.assetAmount && formatter(Number(item.assetAmount))) || '-'}
            </td>
            <td style={{ minWidth: '200px' }}>{formatter(Number(item.amount))}</td>
            <td style={{ minWidth: '200px' }}>{item.status}</td>
            <td style={{ minWidth: '200px' }}>
              <div className='d-flex gap-2 w-auto'>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onViewClick(item)
                  }}
                >
                  View
                </Button>
                {item.approvedById === null ? (
                  <Button
                    size='sm'
                    disabled={
                      item.status === ContractStatus.DRAFT
                        ? false
                        : item.updatedBy.externalId === user?.sub
                    }
                    variant='primary'
                    className='float-end d-flex align-items-center'
                    onClick={(e) => {
                      onUpdateClick(item)
                    }}
                  >
                    {item.status === ContractStatus.PENDING ? 'Approve' : 'Edit'}
                  </Button>
                ) : item.status === ContractStatus.PENDING_APPROVAL_DISTRIBUTION ? (
                  <Button
                    size='sm'
                    variant='success'
                    className='float-end d-flex align-items-center'
                    disabled={item.updatedBy.externalId === user?.sub}
                    onClick={(e) => {
                      onUpdateTransactionClick(item)
                    }}
                  >
                    Approve
                  </Button>
                ) : item.status === ContractStatus.APPROVED_FOR_DISTRIBUTION ||
                  item.status === ContractStatus.SETTLED ? (
                  <Button
                    size='sm'
                    variant='success'
                    className='float-end d-flex align-items-center'
                    onClick={(e) => {
                      onTransactionDetailClick(item)
                    }}
                  >
                    Transactions
                  </Button>
                ) : item.status === ContractStatus.FAILED ? (
                  <Button
                    size='sm'
                    variant='success'
                    className='float-end d-flex align-items-center'
                    onClick={(e) => {
                      onResubmitClick(item)
                    }}
                  >
                    Transactions
                  </Button>
                ) : (
                  <Button
                    size='sm'
                    variant='success'
                    className='float-end d-flex align-items-center'
                    onClick={(e) => {
                      onTransactionClick(item)
                    }}
                  >
                    Transactions
                  </Button>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default AssetContractsManagmentTable
