import { StringArraySupportOption } from 'prettier'
import { IUserResponse } from '../user/userType'

interface IOrganization {
  createdAt: string
  externalId: string
  id: string
  name: string
  updatedAt: string
  fullname: string
}

export interface IAssetResponse {
  id: string
  organizationId: string
  name: string
  symbol: string
  currencyAssetId?: string
  isImportedToken: boolean
  price?: string
  maxSupply?: string
  custodianId: string
  status: AssetStatus
  createdById: string
  updatedById: string
  approvedById: string
  decimalPlaces: number
  smartContractAddress: string
  createdAt: string
  updatedAt: string
  type: AssetType
  detailAsset: string
  approvedBy?: IUserResponse
  comment: string
  createdBy: IUserResponse
  currencyAsset: IAssetResponse | null
  custodian: IUserResponse
  organization: IOrganization
  updatedBy: IOrganization
}

export enum AssetType {
  LOAN = 'LOAN',
  CURRENCY = 'CURRENCY',
}

export const AssetTypeOption = [
  {
    label: 'Currency',
    value: AssetType.CURRENCY,
  },
  {
    label: 'Loan',
    value: AssetType.LOAN,
  },
]

export interface IPostCurrencyAsset {
  price?: string
  name?: string
  type?: string
  quantity?: string
  custodian?: string
  asset_detail?: string
  decimal?: number
}

export interface IPostLoanAsset {
  price?: string
  name?: string
  type?: string
  quantity?: string
  custodian?: string
  asset_detail?: string
}

export interface IPostAssetBody {
  price?: null | string
  name?: null | string
  type: AssetType
  symbol?: null | string
  currencyAssetId?: null | string
  maxSupply?: null | string
  custodianId: string
  decimalPlaces?: null | number
  status: PostAssetStatus
  detailAsset: string
  isImportedToken: boolean
  smartContractAddress?: null | string
}

export enum PostAssetStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
}

export enum AssetStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export enum CreationType {
  NEW = 'NEW',
  IMPORT = 'IMPORT',
}

export const CreationTypeOption = [
  {
    label: 'New Token',
    value: CreationType.NEW,
  },
  {
    label: 'Import Token',
    value: CreationType.IMPORT,
  },
]

export enum ContractStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  PENDING_APPROVAL_DISTRIBUTION = 'PENDING_APPROVAL_DISTRIBUTION',
  APPROVED_FOR_DISTRIBUTION = 'APPROVED_FOR_DISTRIBUTION',
  SETTLED = 'SETTLED',
  FAILED = 'FAILED',
  DRAFT_DISTRIBUTION = 'DRAFT_DISTRIBUTION',
}

export const IS_TRANSACTIONS_STATUS = (status: string) =>
  status === ContractStatus.PENDING_APPROVAL_DISTRIBUTION ||
  status === ContractStatus.APPROVED_FOR_DISTRIBUTION ||
  status === ContractStatus.SETTLED ||
  status === ContractStatus.FAILED ||
  status === ContractStatus.DRAFT_DISTRIBUTION

export interface IAssetContract {
  assetType: string
  asset: string
  quantity: string
  contractType: string
  condition: string
  detail: string
}

// contracts

export interface IContractResponse {
  amount: string
  approvedBy: IUserResponse | null
  approvedById: string | null
  asset: IAssetResponse
  assetId: string
  cashflowDetail: string
  condition: string
  createdAt: string
  createdBy: IUserResponse
  createdById: string
  distributionDate: string
  settledAt: string
  id: string
  organization: IOrganization
  organizationId: string
  status: ContractStatus
  type: ContractTypeEnum
  updatedAt: string
  updatedBy: IUserResponse
  updatedById: string
  users?: IUserResponse[]
  comment?: string
  assetAmount?: string
  sellerUserId?: string
  sellerUser?: IUserResponse
  buyerUserId?: string
  buyerUser?: IUserResponse
}

export interface IPostContractAsset {
  userIds?: string[] | any
  assetId?: string
  amount: string
  type: string
  status: string
  distributionDate?: string
  condition: string
  cashflowDetail: string
  assetAmount?: string
  buyerUserId?: string | any
  sellerUserId?: string | any
}

export enum ContractTypeEnum {
  ASSET_SUBSCRIPTION = 'ASSET_SUBSCRIPTION',
  LOAN_DISBURSEMENT = 'LOAN_DISBURSEMENT',
  LOAN_AUTODEBIT = 'LOAN_AUTODEBIT',
  YIELD_DISBURSEMENT = 'YIELD_DISBURSEMENT',
  SWAP = 'SWAP',
  ONCHAIN_SWAP = 'ONCHAIN_SWAP',
  TOKEN_BURN = 'TOKEN_BURN',
}

export const ContractTypeOption: Array<{ label: string; value: string }> = [
  {
    label: 'Asset Subscription',
    value: ContractTypeEnum.ASSET_SUBSCRIPTION,
  },
  {
    label: 'Loan Disbursement',
    value: ContractTypeEnum.LOAN_DISBURSEMENT,
  },
  {
    label: 'Loan Autodebit',
    value: ContractTypeEnum.LOAN_AUTODEBIT,
  },
  {
    label: 'Yield Disbursement',
    value: ContractTypeEnum.YIELD_DISBURSEMENT,
  },
  {
    label: 'Asset Swap',
    value: ContractTypeEnum.SWAP,
  },
  {
    label: 'Atomic Swap',
    value: ContractTypeEnum.ONCHAIN_SWAP,
  },
  {
    label: 'Token Burn',
    value: ContractTypeEnum.TOKEN_BURN,
  },
]

export const getContractTypeLabel = (val: ContractTypeEnum) =>
  ContractTypeOption.find((type) => type.value === val)?.label

export const SWAP_TYPE = (value: string) =>
  value === ContractTypeEnum.SWAP || value === ContractTypeEnum.ONCHAIN_SWAP

export interface ITransactionResponse {
  id: string
  contractId: string
  userId: string
  amount: string
  createdAt: string
  updatedAt: string
  contract: IContractResponse
  user: IUserResponse
  assetAmount?: string
  sellerUser: IUserResponse
  sellerUserId: string
}

export interface IGetTransactionParams {
  from: number
  size: number
  contractId: string
  userId?: string
}

export interface IUserBalanceResponse {
  id: string
  email: string
  fullname: string
  type: string
  bankCurrency: string
  bankName: string
  bankNumber: string
  bankSwift: string
  organizationId: string
  username: string
  walletAddress: string
  balance: string
}

export interface IAssetBalanceResponse {
  id: string
  organizationId: string
  name: string
  symbol: string
  currencyAssetId: string
  price: string
  maxSupply: string
  custodianId: string
  status: string
  createdById: string
  updatedById: string
  approvedById: string
  decimalPlaces: number
  smartContractAddress: string
  balance: string
  createdAt: string
  updatedAt: string
}
