import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import QuantityAmount from './QuantityInput'
import AmountInput from './AmountInput'
import { ITransactionTable } from '../types'

const TransactionsSwapTable: React.FC<ITransactionTable> = ({
  transactiondata,
  setIsLoading,
  isLoading,
  handleGetTransaction,
  contractdata,
}) => {
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>User</th>
            <th>Quantity</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactiondata.map((transaction, index) => {
            return (
              <>
                <tr key={index}>
                  <td className='w-50'>{transaction.user.fullname} (Payer)</td>
                  <td>
                    <div className='d-flex align-content-center'>
                      <QuantityAmount
                        setIsLoading={(val: boolean) => setIsLoading(val)}
                        isLoading={isLoading}
                        transaction={transaction}
                        refetch={() => handleGetTransaction(0)}
                        price={
                          (contractdata?.amount &&
                            contractdata?.assetAmount &&
                            Number(contractdata.amount) / Number(contractdata.assetAmount)) ||
                          0
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-content-center'>
                      <AmountInput
                        setIsLoading={(val: boolean) => setIsLoading(val)}
                        isLoading={true}
                        price={
                          (contractdata?.amount &&
                            contractdata.assetAmount &&
                            Number(transaction.assetAmount) *
                              (Number(contractdata.amount) / Number(contractdata.assetAmount))) ||
                          0
                        }
                        transaction={transaction}
                        refetch={() => handleGetTransaction(0)}
                      />
                    </div>
                  </td>
                </tr>
                <tr key={index}>
                  <td className='w-50'>{transaction?.sellerUser?.fullname} (Payee)</td>
                  <td>
                    <QuantityAmount
                      setIsLoading={(val: boolean) => setIsLoading(val)}
                      isLoading={isLoading}
                      transaction={transaction}
                      refetch={() => handleGetTransaction(0)}
                      disabled={true}
                      price={
                        (contractdata?.amount &&
                          contractdata?.assetAmount &&
                          Number(contractdata.amount) / Number(contractdata.assetAmount)) ||
                        0
                      }
                    />
                  </td>
                  <td>
                    <AmountInput
                      setIsLoading={(val: boolean) => setIsLoading(val)}
                      isLoading={isLoading}
                      transaction={transaction}
                      refetch={() => handleGetTransaction(0)}
                      price={
                        (contractdata?.amount &&
                          contractdata?.assetAmount &&
                          Number(transaction.assetAmount) *
                            (Number(contractdata.amount) / Number(contractdata.assetAmount))) ||
                        0
                      }
                      disabled={true}
                    />
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default TransactionsSwapTable
