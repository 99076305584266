import { Stack } from 'react-bootstrap'
import Select, { OptionProps, SingleValue, Props } from 'react-select'
import UserAvatar from '../UserAvatar'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { User } from '../../interfaces'
import { IUserResponse } from '../../api/user/userType'

const CustomOption = ({ innerProps, data }: { innerProps: OptionProps; data: IUserResponse }) => (
  <Stack
    {...innerProps}
    direction='horizontal'
    className='p-2 btn btn-outline-primary border-0 no-radius'
    gap={2}
  >
    <UserAvatar username={data.fullname ?? 'member'} />
  </Stack>
)

const customFilter = (option: FilterOptionOption<any>, searchText: string) => {
  // Use a case-insensitive search
  const label = option.data.label.toLowerCase()
  const search = searchText.toLowerCase()
  return label.includes(search)
}

const UserSelect: React.FC<Props> = (props) => (
  <>
    {' '}
    <Select
      // options={ledgerAssets}
      placeholder='Select user'
      // isDisabled={isLoading}
      components={{ Option: CustomOption as never }}
      filterOption={customFilter}
      {...props}
    />
  </>
)

export default UserSelect
