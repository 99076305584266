import { AxiosResponse } from 'axios'
import axiosInstance from '../axios'
import { IPostBalanceTransactionBody } from './transactionType'

export const postBalanceTransaction = (
  body: IPostBalanceTransactionBody,
): Promise<AxiosResponse<any>> => axiosInstance.post('/transfers', body)

export const getBalanceTransactionById = (id: string): Promise<AxiosResponse<any>> =>
  axiosInstance.get(`/transfers/${id}`)

export const putBalanceTransaction = (
  id: string,
  body: Partial<IPostBalanceTransactionBody>,
): Promise<AxiosResponse<any>> => axiosInstance.put(`/transfers/${id}`, body)

export const postApproveBalanceTransaction = (
  id: string,
  body: {
    comment?: string
  },
): Promise<AxiosResponse<any>> => axiosInstance.post(`/transfers/${id}/approve`, body)

export const postRejectBalanceTransaction = (
  id: string,
  body: {
    comment?: string
  },
): Promise<AxiosResponse<any>> => axiosInstance.post(`/transfers/${id}/reject`, body)
