import React from 'react'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import './App.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Login from './pages/Login'
import NoMatch from './pages/404'
import Home from './pages/Home'
import { toast, ToastContainer } from 'react-toastify'
import useUserStore from './stores/userStore'
import { getLocalStorage } from './stores/local_storage'
import AssetManagementPage from './pages/AssetManagement'
import AdminPage from './pages/Admin'
import UserPage from './pages/User'
import AssetContractsPage from './pages/AssetContracts'
// import ContractTransactionsPage from './pages/ContractTransactions'
import CreditTopup from './pages/CreditTopup'

function App() {
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const authUser = getLocalStorage()?.user
    const { setUser } = useUserStore()
    const location = useLocation()

    if (!authUser) {
      return <Navigate to='/login' state={{ from: location }} replace />
    }

    return children
  }

  const { isSessionExpired, setIsSessionExpired } = useUserStore()
  const authUser = getLocalStorage()?.user
  const { setUser, user } = useUserStore()

  React.useEffect(() => {
    if (isSessionExpired) {
      localStorage.clear()

      setTimeout(() => {
        toast.warning('Your session has expired. Please login again.')
        setIsSessionExpired(false)
      }, 100)
    }
  }, [isSessionExpired])

  React.useEffect(() => {
    if (authUser) {
      setUser(authUser)
    }
  }, [])

  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route
          path='/'
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        >
          <Route path='*' element={<NoMatch />} />
          {user && user['urn:zitadel:iam:org:project:roles'] !== undefined ? (
            <>
              <Route path='/asset-management' element={<AssetManagementPage />} />
              <Route path='/asset-management/:tokenid' element={<AssetContractsPage />} />
              {/* <Route
                path='/asset-management/:tokenid/:contractid'
                element={<ContractTransactionsPage />}
              /> */}
              <Route path='/admins' element={<AdminPage />} />
              <Route path='/users' element={<UserPage />} />
            </>
          ) : (
            <Route path='/credit-topup' element={<CreditTopup />} />
          )}
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Routes>
      <ToastContainer pauseOnHover={false} autoClose={2000} />
    </>
  )
}

export default App
