import moment from 'moment-timezone'
import { User } from '../interfaces'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export const generateColorFromString = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase()
  return '#' + '00000'.substring(0, 6 - c.length) + c
}

function getLang() {
  if (navigator.languages != undefined) return navigator.languages[0]
  return navigator.language
}

export const formatter = (value: number) => {
  return new Intl.NumberFormat(getLang(), {
    style: 'currency',
    currency: 'IDR',
    currencyDisplay: 'code',
  })
    .format(value)
    .replace(/[a-z]{3}/i, '')
    .trim()
}

export const formatDateWithTimezone = (data: string) => {
  const createdAtTimezone = moment(data).utcOffset() / 60
  return moment(data).format('YYYY-MM-DD HH:mm:ss')
}

export const getOffsetTimezone = () => {
  const createdAtTimezone = moment().utcOffset() / 60
  return `GMT${createdAtTimezone > 0 ? `+${createdAtTimezone}` : createdAtTimezone}, ${
    moment.tz.guess().split('/').length > 0 && moment.tz.guess().split('/')[1]
  }`
}

export const getLoggedUser = () => JSON.parse(localStorage.getItem('user') || '{}') as User

export const debounce = <T extends (...args: any) => any>(func: T, wait?: number) => {
  let timeout: NodeJS.Timeout | number | undefined
  return (...args: any) => {
    const later = () => {
      timeout = undefined

      func(...args)
    }
    clearTimeout(timeout as number | undefined)

    timeout = setTimeout(later, wait)
  }
}

export const handleAxiosError = (error: any) => {
  if (!(error instanceof AxiosError)) return
  if (error?.response?.data && Array.isArray(error.response.data.message)) {
    const errArray: string[] = error.response.data.message
    errArray.forEach((err) => {
      const errStringArr = err.split(', ')
      errStringArr.forEach((text) => {
        toast.error(text)
      })
    })
  }
}

export const currencyStringToNumber = (str: string) => str.replaceAll(',', '')

export const formatWalletAddress = (val: string) => val.substring(0, 10) + '...'
