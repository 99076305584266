import React, { useState } from 'react'
import { Card, Col, Row, Spinner, Table, Button, Form, Modal, Image } from 'react-bootstrap'
import NoData from '../components/NoData'
import usePagination from '../hooks/usePagination'
import { HiPlus } from 'react-icons/hi'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues } from 'react-hook-form'
import * as yup from 'yup'
import checked from '../assets/images/checked.png'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { postUser, putUserProfile } from '../api/user/user'
import { IPostUserBody, IUserResponse, UserType, UserTypeOptions } from '../api/user/userType'
import { UserRole } from '../interfaces'
import { handleAxiosError } from '../helper'
import Select, { SingleValue } from 'react-select'

const UserTable = (props: {
  list: IUserResponse[]
  onUpdateClick: (data: IUserResponse) => void
  onViewClick: (data: IUserResponse) => void
}) => {
  const { onUpdateClick, onViewClick } = props

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Type</th>
          <th>Bank Number</th>
          <th>Bank Currency</th>
          <th>Bank Name</th>
          <th>Bank Swift</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td>{item.fullname}</td>
            <td>{item.email}</td>
            <td>{item.type}</td>
            <td>{item.bankNumber}</td>
            <td>{item.bankCurrency}</td>
            <td>{item.bankName}</td>
            <td>{item.bankSwift}</td>
            <td>
              <div className='d-flex gap-2 w-auto'>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onUpdateClick(item)
                  }}
                >
                  Edit
                </Button>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onViewClick(item)
                  }}
                >
                  View
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const userSchema = yup
  .object({
    email: yup.string().required(),
    fullname: yup.string().required(),
    type: yup.string().required(),
    bankCurrency: yup.string().required(),
    bankName: yup.string().required(),
    bankNumber: yup.string().required(),
    bankSwift: yup.string().required(),
    externalWalletAddress: yup.string().optional(),
  })
  .required()

const UserCreateModal = (props: { show: boolean; onHide: () => void }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(userSchema),
  })

  const onSubmit = async (data: IPostUserBody) => {
    setIsLoading(true)

    try {
      const response = await postUser({
        fullname: data.fullname,
        email: data.email,
        bankName: data.bankName,
        bankNumber: data.bankNumber,
        bankCurrency: data.bankCurrency,
        bankSwift: data.bankSwift,
        type: data.type,
        externalWalletAddress: data.externalWalletAddress,
      })

      setIsComplete(true)
      reset()
    } catch (error) {
      handleAxiosError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>New User</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>User created successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>
                Name <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter name'
                {...register('fullname', { required: true })}
                onChange={(e) => {
                  setValue('fullname', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.fullname?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>
                Email <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='email'
                placeholder='Enter email'
                {...register('email', { required: true })}
                onChange={(e) => {
                  setValue('email', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='type'>
              <Form.Label>
                Type <sup>*</sup>
              </Form.Label>
              <Select
                options={[
                  {
                    label: 'CORPORATE',
                    value: UserType.CORPORATE,
                  },
                  {
                    label: 'INDIVIDUAL',
                    value: UserType.INDIVIDUAL,
                  },
                ]}
                placeholder='Select user type'
                isDisabled={isLoading}
                onChange={(option: SingleValue<{ label: string; value: UserType }>) => {
                  setValue('type', option?.value || '')
                }}
              />
              <Form.Text className='text-danger'>{errors.type?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankNumber'>
              <Form.Label>
                Bank Number <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='number'
                placeholder='Enter bank number'
                {...register('bankNumber', { required: true })}
                onChange={(e) => {
                  setValue('bankNumber', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.bankNumber?.message?.toString()}
              </Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankCurrency'>
              <Form.Label>
                Bank Currency <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank currency'
                {...register('bankCurrency', { required: true })}
                onChange={(e) => {
                  setValue('bankCurrency', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.bankCurrency?.message?.toString()}
              </Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankName'>
              <Form.Label>
                Bank Name <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank name'
                {...register('bankName', { required: true })}
                onChange={(e) => {
                  setValue('bankName', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.bankName?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankSwift'>
              <Form.Label>
                Bank Swift <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank swift'
                {...register('bankSwift', { required: true })}
                onChange={(e) => {
                  setValue('bankSwift', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.bankSwift?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='externalWallet'>
              <Form.Label>External Wallet Adress</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter external wallet'
                disabled={isLoading}
                {...register('externalWalletAddress', { required: true })}
                onChange={(e) => {
                  setValue('externalWalletAddress', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.externalWalletAddress?.message?.toString()}
              </Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const UserUpdateModal = (props: {
  show: boolean
  onHide: () => void
  selectedUser?: IUserResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedUser } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(userSchema),
    values: {
      fullname: selectedUser?.fullname || '',
      email: selectedUser?.email || '',
      type: selectedUser?.type || '',
      bankName: selectedUser?.bankName || '',
      bankCurrency: selectedUser?.bankCurrency || '',
      bankNumber: selectedUser?.bankNumber || '',
      bankSwift: selectedUser?.bankSwift || '',
    },
  })

  const onSubmit = async (data: IPostUserBody) => {
    setIsLoading(true)

    try {
      if (!selectedUser) return

      const response = await putUserProfile(selectedUser.id, {
        fullname: data.fullname,
        email: data.email,
        bankName: data.bankName,
        bankNumber: data.bankNumber,
        bankCurrency: data.bankCurrency,
        bankSwift: data.bankSwift,
        type: data.type,
        externalWalletAddress: data.externalWalletAddress,
      })

      // if (response.status === 201) {
      setIsComplete(true)
      reset()
      // }
    } catch (error) {
      handleAxiosError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Update User</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>User updated successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>
                Name <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter name'
                {...register('fullname', { required: true })}
                onChange={(e) => {
                  setValue('fullname', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.fullname?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>
                Email <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='email'
                placeholder='Enter email'
                {...register('email', { required: false })}
                onChange={(e) => {
                  setValue('email', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='type'>
              <Form.Label>
                Type <sup>*</sup>
              </Form.Label>
              <Select
                options={UserTypeOptions}
                value={UserTypeOptions.find((type) => type.value === watch('type'))}
                placeholder='Select asset'
                isDisabled={isLoading}
                onChange={(option: SingleValue<{ label: string; value: UserType }>) => {
                  setValue('type', option?.value || '')
                }}
              />
              <Form.Text className='text-danger'>{errors.type?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankNumber'>
              <Form.Label>
                Bank Number <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank number'
                {...register('bankNumber', { required: false })}
                onChange={(e) => {
                  setValue('bankNumber', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.bankNumber?.message?.toString()}
              </Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankCurrency'>
              <Form.Label>
                Bank Currency <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank currency'
                {...register('bankCurrency', { required: false })}
                onChange={(e) => {
                  setValue('bankCurrency', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.bankCurrency?.message?.toString()}
              </Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankName'>
              <Form.Label>
                Bank Name <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank name'
                {...register('bankName', { required: false })}
                onChange={(e) => {
                  setValue('bankName', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.bankName?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='bankSwift'>
              <Form.Label>
                Bank Swift <sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter bank swift'
                {...register('bankSwift', { required: false })}
                onChange={(e) => {
                  setValue('bankSwift', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.bankSwift?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='externalWallet'>
              <Form.Label>External Wallet Adress</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter external wallet'
                disabled={isLoading}
                {...register('externalWalletAddress', { required: true })}
                onChange={(e) => {
                  setValue('externalWalletAddress', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>
                {errors.externalWalletAddress?.message?.toString()}
              </Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const UserViewModal = (props: {
  show: boolean
  onHide: () => void
  selectedUser?: IUserResponse
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedUser } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>User Detail</h5>
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={() => null}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={selectedUser?.fullname}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter name'
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={selectedUser?.email}
              disabled={true}
              defaultValue=''
              type='email'
              placeholder='Enter email'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='type'>
            <Form.Label>Type</Form.Label>
            <Select
              options={UserTypeOptions}
              value={UserTypeOptions.find((type) => type.value === selectedUser?.type)}
              placeholder='Select asset'
              isDisabled={true}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='bankNumber'>
            <Form.Label>Bank Number</Form.Label>
            <Form.Control
              value={selectedUser?.bankNumber}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter bank number'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='bankCurrency'>
            <Form.Label>Bank Currency</Form.Label>
            <Form.Control
              value={selectedUser?.bankCurrency}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter bank currency'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='bankName'>
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              value={selectedUser?.bankName}
              disabled={true}
              defaultValue=''
              type='text'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='bankSwift'>
            <Form.Label>Bank Swift</Form.Label>
            <Form.Control
              value={selectedUser?.bankSwift}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter bank swift'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='externalWallet'>
            <Form.Label>External Wallet Adress</Form.Label>
            <Form.Control
              value={selectedUser?.externalWalletAddress}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter external wallet'
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              onHide()
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const UserPage = () => {
  const [modalEditShow, setModalEditShow] = useState(false)
  const [modalDetailShow, setModalDetailShow] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUserResponse>()
  const [modalShow, setModalShow] = React.useState(false)
  const { data, isLoading, renderPagination, refetch } = usePagination<IUserResponse>({
    queryKey: ['getUsers'],
    url: '/users',
    handlePageParams: true,
  })

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Users</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>List</h6>
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New User
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <UserTable
                          list={data}
                          onUpdateClick={(user) => {
                            setModalEditShow(true)
                            setSelectedUser(user)
                          }}
                          onViewClick={(user) => {
                            setModalDetailShow(true)
                            setSelectedUser(user)
                          }}
                        />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UserCreateModal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          refetch()
        }}
      />
      <UserUpdateModal
        show={modalEditShow}
        onHide={() => {
          setModalEditShow(false)
          refetch()
        }}
        selectedUser={selectedUser}
      />
      <UserViewModal
        show={modalDetailShow}
        onHide={() => {
          setModalDetailShow(false)
        }}
        selectedUser={selectedUser}
      />
    </>
  )
}

export default UserPage
