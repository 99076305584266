import React, { ChangeEvent, useState } from 'react'
import {
  ContractTypeEnum,
  IContractResponse,
  ITransactionResponse,
  SWAP_TYPE,
} from '../../api/assets_managment/assetsManagmentType'
import { IResponseTemplate } from '../../api/types'
import {
  getTransactions,
  postResubmitDistributionContract,
} from '../../api/assets_managment/assetsManagement'
import CurrencyInput from '../../components/Input/CurrencyInput'
import { Button, Form, Image, Modal, Spinner, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import checked from '../../assets/images/checked.png'
import TransactionsSwapTable from './Transactions/TransactionsTableSwap'
import TransactionsTable from './Transactions/TransactionTable'

const ContractResubmitTransactionsModal = (props: {
  show: boolean
  onHide: () => void
  selectedcontract?: IContractResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)
  const [page, setPage] = useState(0)
  const [transactionData, setTransactionData] =
    useState<IResponseTemplate<ITransactionResponse[]>>()
  const [comment, setComment] = useState<string>()

  const PAGE_SIZE = 10

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedcontract } = props

  const handleReSubmitTransactions = async () => {
    if (!selectedcontract) return
    setIsLoading(true)
    try {
      await postResubmitDistributionContract(selectedcontract.id)

      setIsComplete(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGetTransaction = async (from: number) => {
    try {
      if (!selectedcontract) return
      const res = await getTransactions({
        contractId: selectedcontract.id,
        from: from,
        size: PAGE_SIZE,
      })
      setTransactionData(res.data)
    } catch (error) {
      //
    }
  }
  const handlePageChange = async (page: number) => {
    try {
      setPage(page)
      handleGetTransaction(page * PAGE_SIZE)
    } catch (error) {
      //
    }
  }

  React.useEffect(() => {
    if (selectedcontract) {
      handleGetTransaction(0)
      setComment(selectedcontract.comment)
    }
  }, [selectedcontract])

  const AmountInput: React.FC<{
    transaction: ITransactionResponse
    isLoading: boolean
    setIsLoading: (val: boolean) => void
    refetch: () => void
  }> = ({ transaction, isLoading, setIsLoading, refetch }) => {
    const [value, setValue] = React.useState<string>()

    React.useEffect(() => {
      if (transaction) {
        setValue(transaction.amount)
      }
    }, [transaction])

    return (
      <>
        <td className='w-50'>{transaction.user.fullname}</td>
        <td>
          <CurrencyInput
            className='form-control'
            disabled={true}
            value={value && Number(value)}
            type='text'
            placeholder='Enter amount'
          />
        </td>
      </>
    )
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      size='lg'
      centered
      onHide={() => {
        setIsComplete(false)
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Resubmit Transactions</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Transactions resubmit successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            {selectedcontract && SWAP_TYPE(selectedcontract.type)
              ? transactionData?.data && (
                  <TransactionsSwapTable
                    transactiondata={transactionData.data}
                    contractdata={selectedcontract}
                    isLoading={true}
                    setIsLoading={setIsLoading}
                    handleGetTransaction={handleGetTransaction}
                  />
                )
              : transactionData?.data && (
                  <TransactionsTable
                    transactiondata={transactionData.data}
                    totalpage={transactionData.totalCount}
                    isLoading={true}
                    setIsLoading={setIsLoading}
                    handleGetTransaction={handleGetTransaction}
                    handlePageChange={handlePageChange}
                  />
                )}

            {selectedcontract && SWAP_TYPE(selectedcontract.type) && (
              <Form.Group className='mb-3' controlId='price'>
                <Form.Label>Price</Form.Label>
                <CurrencyInput
                  className='form-control'
                  disabled={true}
                  value={Number(selectedcontract.amount) / Number(selectedcontract.assetAmount)}
                  type='text'
                />
              </Form.Group>
            )}
            <Form.Group className='mb-3' controlId='comment'>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                disabled={true}
                as='textarea'
                type='text'
                placeholder='Enter comment'
                value={comment}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setComment(e.target.value)
                }}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => handleReSubmitTransactions()}
              type='button'
              disabled={isLoading}
            >
              Re-submit for Distribution
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

export default ContractResubmitTransactionsModal
