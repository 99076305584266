import React from 'react'
import useUserStore from '../../stores/userStore'
import { Button, Table } from 'react-bootstrap'
import { formatter, formatWalletAddress } from '../../helper'
import { IBalanceTransactionResponse } from '../../api/transactions/transactionType'
import { ContractStatus } from '../../api/assets_managment/assetsManagmentType'

const BalanceTransactionTable = (props: {
  list: IBalanceTransactionResponse[]
  handleApprove: (data: IBalanceTransactionResponse) => void
  handleEdit: (data: IBalanceTransactionResponse) => void
  handleViewDetail: (data: IBalanceTransactionResponse) => void
}) => {
  const { list, handleViewDetail, handleApprove, handleEdit } = props

  const { user } = useUserStore()

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Payer</th>
          <th>Payee</th>
          <th>Wallet Address (Payer)</th>
          <th>Wallet Address (Payee)</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td>{item.senderUser?.fullname || '-'}</td>
            <td>{item.receiverUser?.fullname || '-'}</td>
            <td>
              {item.senderUser?.walletAddress
                ? formatWalletAddress(item.senderUser.walletAddress)
                : '-'}{' '}
            </td>
            <td>
              {item.receiverUser?.walletAddress
                ? formatWalletAddress(item.receiverUser.walletAddress)
                : '-'}{' '}
            </td>
            <td>{formatter(Number(item.amount))}</td>
            <td>{item.status}</td>
            <td style={{ minWidth: '200px' }}>
              <div className='d-flex gap-2 w-auto'>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    //
                    handleViewDetail(item)
                  }}
                >
                  View
                </Button>
                {item.approvedById === null && (
                  <Button
                    size='sm'
                    disabled={
                      item.status === ContractStatus.DRAFT
                        ? false
                        : item.updatedBy.externalId === user?.sub
                    }
                    variant='primary'
                    className='float-end d-flex align-items-center'
                    onClick={(e) => {
                      if (item.status === ContractStatus.PENDING) {
                        handleApprove(item)
                      } else {
                        handleEdit(item)
                      }
                    }}
                  >
                    {item.status === ContractStatus.PENDING ? 'Approve' : 'Edit'}
                  </Button>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default BalanceTransactionTable
