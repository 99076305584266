import React, { useState } from 'react'
import {
  ContractTypeEnum,
  IContractResponse,
  ITransactionResponse,
  SWAP_TYPE,
} from '../../api/assets_managment/assetsManagmentType'
import { IResponseTemplate } from '../../api/types'
import {
  getTransactions,
  putTransaction,
  setContractPendingDistribution,
} from '../../api/assets_managment/assetsManagement'
import { currencyStringToNumber, debounce, handleAxiosError } from '../../helper'
import { MaskedInputProps } from 'react-text-mask'
import CurrencyInput from '../../components/Input/CurrencyInput'
import { Button, Col, Form, Image, Modal, Row, Spinner, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import checked from '../../assets/images/checked.png'
import { boolean } from 'yup'
import { TRANSACTIONS_PAGE_SIZE } from './types'
import TransactionsSwapTable from './Transactions/TransactionsTableSwap'
import TransactionsTable from './Transactions/TransactionTable'

const ContractTransactionsModal = (props: {
  show: boolean
  onHide: () => void
  selectedcontract?: IContractResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)
  const [page, setPage] = useState(0)
  const [transactionData, setTransactionData] =
    useState<IResponseTemplate<ITransactionResponse[]>>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedcontract } = props

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      if (!selectedcontract) return
      await setContractPendingDistribution(selectedcontract.id)
      setIsComplete(true)
    } catch (error) {
      handleAxiosError(error)
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  const handleGetTransaction = async (from: number) => {
    try {
      if (!selectedcontract) return
      const res = await getTransactions({
        contractId: selectedcontract.id,
        from: from,
        size: TRANSACTIONS_PAGE_SIZE,
      })
      setTransactionData(res.data)
    } catch (error) {
      //
    }
  }
  const handlePageChange = async (page: number) => {
    try {
      setPage(page)
      handleGetTransaction(page * TRANSACTIONS_PAGE_SIZE)
    } catch (error) {
      //
    }
  }

  React.useEffect(() => {
    if (selectedcontract) {
      handleGetTransaction(0)
    }
  }, [selectedcontract])

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      size='lg'
      onHide={() => {
        setIsComplete(false)
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Transactions</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Transactions Submited successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            {selectedcontract && SWAP_TYPE(selectedcontract.type)
              ? transactionData?.data && (
                  <TransactionsSwapTable
                    transactiondata={transactionData.data}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    contractdata={selectedcontract}
                    handleGetTransaction={handleGetTransaction}
                  />
                )
              : transactionData?.data && (
                  <TransactionsTable
                    transactiondata={transactionData.data}
                    totalpage={transactionData.totalCount}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    handleGetTransaction={handleGetTransaction}
                    handlePageChange={handlePageChange}
                  />
                )}

            {selectedcontract && SWAP_TYPE(selectedcontract.type) && (
              <Form.Group className='mb-3' controlId='price'>
                <Form.Label>Price</Form.Label>
                <CurrencyInput
                  className='form-control'
                  disabled={true}
                  value={Number(selectedcontract.amount) / Number(selectedcontract.assetAmount)}
                  type='text'
                />
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

export default ContractTransactionsModal
