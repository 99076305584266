import React, { useEffect } from 'react'
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg'
import { BiTransfer, BiMoney } from 'react-icons/bi'
import { IoPersonOutline } from 'react-icons/io5'
import { NavLink } from 'react-router-dom'
import useUserStore from '../../stores/userStore'

const Sidebar = () => {
  const { user } = useUserStore()

  return (
    <div className='vertical-menu'>
      <div className='navbar-brandbox'>
        <div className='logo'>
          <Logo />
        </div>
      </div>
      <div className='sidebar-menu'>
        <div className='sidebar-menu-item mb-1'>
          <span className='sidebar-title fs-7 fw-bold'>Overview</span>
          {user && user['urn:zitadel:iam:org:project:roles'] === undefined ? (
            <ul>
              <li>
                <NavLink to={'/credit-topup'}>
                  <BiMoney style={{ height: 26 }} />
                  <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                    Credit Topup
                  </span>
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <NavLink to={'/asset-management'}>
                  <BiTransfer style={{ height: 26 }} />
                  <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                    Asset Management
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={'/admins'}>
                  <IoPersonOutline style={{ height: 26 }} />
                  <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                    Admins
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to={'/users'}>
                  <IoPersonOutline style={{ height: 26 }} />
                  <span className='fw-bold' style={{ marginLeft: 2, fontSize: 15.5 }}>
                    Users
                  </span>
                </NavLink>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
