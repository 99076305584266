import { create } from 'zustand'
// import { User } from '../interfaces'
import { IAdmin } from '../api/admin/adminType'
import { UserProfile } from 'oidc-client-ts'

interface IExtendUser {
  'urn:zitadel:iam:user:resourceowner:id'?: string
  'urn:zitadel:iam:org:project:roles'?: string
}

export interface UserStore {
  user: (UserProfile & IExtendUser) | null
  isSessionExpired: boolean
  setUser: (user: UserProfile) => void
  setIsSessionExpired: (isSessionExpired: boolean) => void
}

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  isSessionExpired: false,
  setUser: (user) => set({ user }),
  setIsSessionExpired: (isSessionExpired) => set({ isSessionExpired }),
}))

export default useUserStore
