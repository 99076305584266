import { AxiosResponse } from 'axios'
import { IUser } from '../../interfaces'
import axiosInstance from '../axios'
import { IAdmin, IPostAdminBody } from './adminType'

export const getAdminsData = (): Promise<AxiosResponse<IAdmin[]>> => axiosInstance.get('/admins')
export const postAdmin = (data: IPostAdminBody): Promise<AxiosResponse<IAdmin>> =>
  axiosInstance.post('/admins', data)
export const putAdminProfile = (
  id: string,
  data: {
    fullname: string
  },
): Promise<AxiosResponse<IAdmin>> => axiosInstance.put(`/admins/${id}/profile`, data)
export const putAdminUsername = (
  id: string,
  data: {
    username: string
  },
): Promise<AxiosResponse<IAdmin>> => axiosInstance.put(`/admins/${id}/username`, data)
export const putAdminEmail = (
  id: string,
  data: {
    email: string
  },
): Promise<AxiosResponse<IAdmin>> => axiosInstance.put(`/admins/${id}/email`, data)
