import React, { ChangeEvent, useState } from 'react'
import {
  ContractTypeEnum,
  ContractTypeOption,
  IAssetResponse,
  IPostContractAsset,
  PostAssetStatus,
  SWAP_TYPE,
} from '../../api/assets_managment/assetsManagmentType'
import usePagination from '../../hooks/usePagination'
import { IUserResponse } from '../../api/user/userType'
import { useForm } from 'react-hook-form'
import { assetContractSchema, balanceTransferSchema, roleoptions } from './types'
import { yupResolver } from '@hookform/resolvers/yup'
import { postNewContract } from '../../api/assets_managment/assetsManagement'
import { currencyStringToNumber, handleAxiosError } from '../../helper'
import { Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap'
import Select, { MultiValue, SingleValue } from 'react-select'
import UserSelect from '../../components/Select/UserSelect'
import CurrencyInput from '../../components/Input/CurrencyInput'
import checked from '../../assets/images/checked.png'
import {
  BalanceTransactionTokenTypeEnum,
  BalanceTransactionTypeEnum,
  IBalanceTransactionResponse,
  IPostBalanceTransactionBody,
  TokenTypeOption,
  TransactionTypeOption,
} from '../../api/transactions/transactionType'
import {
  postApproveBalanceTransaction,
  postBalanceTransaction,
  postRejectBalanceTransaction,
  putBalanceTransaction,
} from '../../api/transactions/transactionApi'

const ContractApproveRejectBalanceTransaction = (props: {
  show: boolean
  onHide: () => void
  assetdata?: IAssetResponse
  selectedData?: IBalanceTransactionResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, show, assetdata, selectedData } = props

  const { data: users } = usePagination<IUserResponse>({
    queryKey: ['getUsersWallet'],
    url: '/users',
    handlePageParams: false,
    pageSize: 99,
    mapDataFn: (users) =>
      users.map((user: IUserResponse) => ({
        ...user,
        value: user.id,
        label: user.fullname,
      })),
    queryParams: {
      allowOrganizationWallet: 'true',
    },
  })
  const [comment, setComment] = useState<string>()

  const handleSubmitApproval = async (type: 'APPROVE' | 'REJECT') => {
    if (!selectedData) return
    setIsLoading(true)
    try {
      if (type === 'APPROVE') {
        await postApproveBalanceTransaction(selectedData.id, { comment: comment })
      } else {
        await postRejectBalanceTransaction(selectedData.id, { comment: comment })
      }
      setIsComplete(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    getValues,
  } = useForm<IPostBalanceTransactionBody>({
    resolver: yupResolver(balanceTransferSchema),
  })

  React.useEffect(() => {
    if (props.assetdata && selectedData) {
      setValue('amount', selectedData.amount)
      setValue('notes', selectedData.notes)
      setValue('type', selectedData.type)
      setValue('tokenType', selectedData.tokenType)
      selectedData.receiverUser &&
        setValue('receiverUserId', {
          label: selectedData.receiverUser.fullname,
          value: selectedData.receiverUser.id,
        })
      selectedData.senderUser &&
        setValue('senderUserId', {
          label: selectedData.senderUser.fullname,
          value: selectedData.senderUser.id,
        })
    }
  }, [selectedData])

  return (
    <Modal
      {...props}
      dialogClassName='modal-90w'
      size='lg'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
        setComment(undefined)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Approve Transfer Balance</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Approval successfully!!</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}
            <Form.Group className='mb-3' controlId='contractType'>
              <Form.Label>Transaction Type</Form.Label>
              <Select
                options={TransactionTypeOption}
                value={TransactionTypeOption.find((type) => type.value === watch('type'))}
                placeholder='Select contract type'
                isDisabled={true}
                onChange={(option: SingleValue<typeof TransactionTypeOption> | any) => {
                  setValue('type', option?.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.type?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='contractType'>
              <Form.Label>Token Type</Form.Label>
              <Select
                options={TokenTypeOption}
                value={TokenTypeOption.find((type) => type.value === watch('tokenType'))}
                placeholder='Select token type'
                isDisabled={true}
              />
              <Form.Text className='text-danger'>{errors.tokenType?.message?.toString()}</Form.Text>
            </Form.Group>
            {selectedData?.type === BalanceTransactionTypeEnum.TRANSFER ? (
              <>
                <Form.Group className='mb-3' controlId='senderUserId'>
                  <Form.Label>Payer</Form.Label>
                  <UserSelect
                    isSearchable={true}
                    options={users}
                    isDisabled={true}
                    isMulti={false}
                    name='senderUserId'
                    value={watch('senderUserId')}
                    onChange={(option: MultiValue<IUserResponse> | any) => {
                      setValue('senderUserId', option.value)
                    }}
                  />
                  <Form.Text className='text-danger'>
                    {errors.senderUserId?.message?.toString()}
                  </Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='receiverUserId'>
                  <Form.Label>Payee</Form.Label>
                  <UserSelect
                    isSearchable={true}
                    options={users}
                    isDisabled={true}
                    isMulti={false}
                    name='receiverUserId'
                    value={watch('receiverUserId')}
                    onChange={(option: MultiValue<IUserResponse> | any) => {
                      setValue('receiverUserId', option.value)
                    }}
                  />
                  <Form.Text className='text-danger'>
                    {errors.receiverUserId?.message?.toString()}
                  </Form.Text>
                </Form.Group>
              </>
            ) : (
              <Form.Group className='mb-3' controlId='senderUserId'>
                <Form.Label>User</Form.Label>
                <UserSelect
                  isSearchable={true}
                  options={users}
                  isDisabled={true}
                  isMulti={false}
                  name='senderUserId'
                  value={watch('senderUserId')}
                  onChange={(option: MultiValue<IUserResponse> | any) => {
                    setValue('senderUserId', option.value)
                  }}
                />
                <Form.Text className='text-danger'>
                  {errors.senderUserId?.message?.toString()}
                </Form.Text>
              </Form.Group>
            )}
            <Form.Group className='mb-3' controlId='amount'>
              <Form.Label>
                {watch('tokenType') === BalanceTransactionTokenTypeEnum.ASSET
                  ? `Qty (${assetdata?.symbol})`
                  : `Amount (${assetdata?.currencyAsset?.symbol})`}{' '}
              </Form.Label>
              <CurrencyInput
                className='form-control'
                disabled={true}
                type='text'
                placeholder='amount'
                value={watch('amount') && Number(watch('amount'))}
                onChange={(e) => {
                  setValue('amount', currencyStringToNumber(e.target.value))
                }}
              />

              <Form.Text className='text-danger'>{errors.amount?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='detail'>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                disabled={true}
                defaultValue=''
                type='text'
                as='textarea'
                placeholder='Enter notes'
                {...register('notes', { required: true })}
                onChange={(e) => {
                  setValue('notes', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.notes?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='username'>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                disabled={isLoading}
                as='textarea'
                type='text'
                placeholder='Enter comment'
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value)
                }}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              type='button'
              onClick={() => handleSubmitApproval('REJECT')}
              disabled={isLoading}
            >
              Reject
            </Button>
            <Button
              variant='primary'
              onClick={() => handleSubmitApproval('APPROVE')}
              type='button'
              disabled={isLoading}
            >
              Approve
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

export default ContractApproveRejectBalanceTransaction
