const zitadelConfig: any = {
  authority: 'https://auth.coral-dev.d3labs.io/', //Replace this with your issuer URL
  client_id: '284599736529388034', //Replace this with your client id
  redirect_uri: 'https://coral-dev.d3labs.io/login',
  // redirect_uri: 'http://localhost:5002/login',
  response_type: 'code',
  scope: 'openid profile urn:zitadel:iam:org:project:roles urn:zitadel:iam:user:resourceowner', //Replace PROJECT_ID with the id of the project where the API resides.
  post_logout_redirect_uri: 'https://coral-dev.d3labs.io/login',
  response_mode: 'query',
  code_challenge_method: 'S256',
}

export default zitadelConfig
