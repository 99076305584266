import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { ReactComponent as Logo } from '../assets/images/logo-vertical.svg'
import useZitadel from '../hooks/useZitadel'

function Login() {
  const { authorize } = useZitadel()

  return (
    <>
      <Container
        className='d-flex justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <div style={{ width: '400px', marginBottom: '132px' }}>
          <Row>
            <Col
              xs={{
                offset: 3,
                span: 6,
              }}
            >
              <Logo />
            </Col>
          </Row>

          <div className='login-with'>
            <div className='login-with__divider'>
              <span>Login With</span>
            </div>
            <div className='login-with__icon'>
              <button className='login-with__icon__item' type='button' onClick={authorize}>
                <div className='login-with__icon__item__icon'>Z</div>
                <div className='login-with__icon__item__text'>Zitadel</div>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login
