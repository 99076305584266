import axios, { AxiosResponse } from 'axios'
import axiosInstance from '../axios'
import { IPostUserBody, IUserResponse } from './userType'

export const getUsersData = (): Promise<AxiosResponse<IUserResponse[]>> =>
  axiosInstance.get('/users')
export const getAdminDataByExternalId = (
  id: string,
  token: string,
): Promise<AxiosResponse<IUserResponse>> =>
  axiosInstance.get(`/admins/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
export const postUser = (data: IPostUserBody): Promise<AxiosResponse<IUserResponse>> =>
  axiosInstance.post('/users', data)
export const putUserProfile = (
  id: string,
  data: IPostUserBody,
): Promise<AxiosResponse<IUserResponse>> => axiosInstance.put(`/users/${id}`, data)
