import React from 'react'
import { Navbar, Nav, Dropdown, Modal, Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AvatarButton from './AvatarButton'
import { FiAlertCircle } from 'react-icons/fi'
import { AiOutlinePoweroff } from 'react-icons/ai'
import { context } from '../stores'
import { BsFlagFill, BsGear } from 'react-icons/bs'
import { getLocalStorage } from '../stores/local_storage'
import { IAdmin } from '../api/admin/adminType'
import useUserStore from '../stores/userStore'
import { UserProfile } from 'oidc-client-ts'

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const CustomToggle = React.forwardRef<any, any>(({ children, onClick }, ref) => {
  return (
    <AvatarButton
      user={children as UserProfile}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    />
  )
})

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const CustomMenu = React.forwardRef<any, any>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <ul className='list-unstyled'>{React.Children.toArray(children)}</ul>
      </div>
    )
  },
)

const ConfirmLogoutModal = (props: {
  show: boolean
  onHide: () => void
  handleConfirm: () => void
}) => {
  return (
    <Modal {...props} size='sm' aria-labelledby='logout-modal' centered>
      <Modal.Header closeButton>
        <Modal.Title id='logout-modal'>
          <h5 className='m-0 fw-bold'>Logout</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <a className='logout-icon text-danger pb-2'>
            <FiAlertCircle />
          </a>
          <span>Are you sure want to logout?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant='primary' type='submit' onClick={props.handleConfirm}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const Header = () => {
  const { user } = useUserStore()

  const [modalShow, setModalShow] = React.useState(false)
  const navigate = useNavigate()

  const logout = () => {
    localStorage.clear()
    navigate('/login')
    toast('Logout successfully', { type: 'success' })
  }

  return (
    <div className='page-topbar'>
      <Navbar bg='light' expand='lg'>
        <Navbar.Collapse id='seaseed-navbar-nav'>
          <Nav className='ms-auto align-items-center'>
            <Dropdown align='end'>
              <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
                {user as any}
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Header>Action</Dropdown.Header>

                <Dropdown.Item
                  onClick={() => setModalShow(true)}
                  className='d-flex align-items-center py-2'
                >
                  <span className='d-inline-flex align-items-center'>
                    <AiOutlinePoweroff />
                  </span>
                  &nbsp; Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ConfirmLogoutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleConfirm={() => logout()}
      />
    </div>
  )
}

export default Header
