import React, { useState } from 'react'
import { Card, Col, Row, Spinner, Table, Button, Form, Modal, Image } from 'react-bootstrap'
import NoData from '../components/NoData'
import usePagination from '../hooks/usePagination'
import { HiPlus } from 'react-icons/hi'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues } from 'react-hook-form'
import * as yup from 'yup'
import checked from '../assets/images/checked.png'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { postAdmin, putAdminEmail, putAdminProfile, putAdminUsername } from '../api/admin/admin'
import { IAdminResponse } from '../api/admin/adminType'
import { UserRole } from '../interfaces'
import { handleAxiosError } from '../helper'
import useUserStore from '../stores/userStore'
import { profile } from 'console'
import { User } from 'oidc-client-ts'

const AdminTable = (props: {
  list: IAdminResponse[]
  onUpdateClick: (data: IAdminResponse) => void
  onViewClick: (data: IAdminResponse) => void
}) => {
  const { onUpdateClick, onViewClick } = props

  const { user } = useUserStore()

  return (
    <Table striped hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Username</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.list.map((item, index) => (
          <tr key={index}>
            <td>{item.fullname}</td>
            <td>{item.username}</td>
            <td>{item.email || '-'}</td>
            <td>
              <div className='d-flex gap-2 w-auto'>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onUpdateClick(item)
                  }}
                  disabled={item.externalId !== user?.sub ? true : false}
                >
                  Edit
                </Button>
                <Button
                  size='sm'
                  variant='primary'
                  className='float-end d-flex align-items-center'
                  onClick={(e) => {
                    onViewClick(item)
                  }}
                >
                  View
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const adminSchema = yup
  .object({
    username: yup.string().required(),
    email: yup.string().required(),
    name: yup.string().required(),
  })
  .required()

const UserCreateModal = (props: { show: boolean; onHide: () => void }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(adminSchema),
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      const response = await postAdmin({
        fullname: data.name,
        username: data.username !== '' ? data.username : undefined,
        email: data.email !== '' ? data.email : undefined,
        role: UserRole.ADMIN,
      })

      if (response.status === 201) {
        setIsComplete(true)
        reset()
      }
    } catch (error) {
      handleAxiosError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>New Admin</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Admin created successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter name'
                {...register('name', { required: true })}
                onChange={(e) => {
                  setValue('name', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.name?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='email'
                placeholder='Enter email'
                {...register('email', { required: false })}
                onChange={(e) => {
                  setValue('email', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='username'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter username'
                {...register('username', { required: true })}
                onChange={(e) => {
                  setValue('username', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.username?.message?.toString()}</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const UserUpdateModal = (props: {
  show: boolean
  onHide: () => void

  selectedUser?: IAdminResponse
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedUser } = props

  const { setUser, user } = useUserStore()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(adminSchema),
    values: {
      name: selectedUser?.fullname || '',
      username: selectedUser?.username || '',
      email: selectedUser?.email || '',
    },
  })

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true)

    try {
      if (!selectedUser) return
      await Promise.all([
        data.email !== selectedUser.email &&
          putAdminEmail(selectedUser.externalId, { email: data.email }),
        data.name !== selectedUser.fullname &&
          putAdminProfile(selectedUser.externalId, { fullname: data.name }),
        data.username !== selectedUser.username &&
          putAdminUsername(selectedUser.externalId, { username: data.username }),
      ])

      if (user) {
        setUser({
          ...user,
          preferred_username: data.username,
          given_name: data.name,
          email: data.email,
        })

        setIsComplete(true)
        reset()
      }
    } catch (error) {
      handleAxiosError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Update Admin</h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>Admin updated successfully</h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter name'
                {...register('name', { required: true })}
                onChange={(e) => {
                  setValue('name', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.name?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='email'
                placeholder='Enter email'
                {...register('email', { required: false })}
                onChange={(e) => {
                  setValue('email', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.email?.message?.toString()}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3' controlId='username'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                disabled={isLoading}
                defaultValue=''
                type='text'
                placeholder='Enter username'
                {...register('username', { required: true })}
                onChange={(e) => {
                  setValue('username', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.username?.message?.toString()}</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            <Button variant='primary' type='submit' disabled={isLoading}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

const UserViewModal = (props: {
  show: boolean
  onHide: () => void

  selectedUser?: IAdminResponse
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, selectedUser } = props

  return (
    <Modal
      {...props}
      dialogClassName='modal-10w'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>Admin Data</h5>
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={() => null}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={selectedUser?.fullname}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter name'
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={selectedUser?.email}
              disabled={true}
              defaultValue=''
              type='email'
              placeholder='Enter email'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='username'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              value={selectedUser?.username}
              disabled={true}
              defaultValue=''
              type='text'
              placeholder='Enter username'
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='light'
            onClick={() => {
              onHide()
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const AdminPage = () => {
  const [modalEditShow, setModalEditShow] = useState(false)
  const [modalDetailShow, setModalDetailShow] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IAdminResponse>()
  const [modalShow, setModalShow] = React.useState(false)
  const { data, isLoading, renderPagination, refetch } = usePagination<IAdminResponse>({
    queryKey: ['getAdmins'],
    url: '/admins',
    handlePageParams: true,
  })

  return (
    <>
      <Row className='g-0 mb-3'>
        <Col className='align-item-center'>
          <h5 className='m-0 fw-bold'>Admins</h5>
        </Col>
      </Row>

      <Row className='g-0'>
        <Col>
          <Card border='light' className='card-shadow'>
            <Card.Body>
              <>
                <Row>
                  <Col className='d-flex align-items-center'>
                    <h6 className='m-0'>List</h6>
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      className='float-end d-flex align-items-center'
                      onClick={(e) => {
                        e.preventDefault()
                        setModalShow(true)
                      }}
                    >
                      <span className='d-inline-flex align-items-center'>
                        <HiPlus />
                      </span>
                      &nbsp; New Admin
                    </Button>
                  </Col>
                </Row>
                <hr />
                {isLoading ? (
                  <div className='d-flex justify-content-center align-items-center p-5'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      <>
                        <AdminTable
                          list={data}
                          onUpdateClick={(user) => {
                            setModalEditShow(true)
                            setSelectedUser(user)
                          }}
                          onViewClick={(user) => {
                            setModalDetailShow(true)
                            setSelectedUser(user)
                          }}
                        />
                        <div className='d-flex justify-content-end align-items-center'>
                          {renderPagination()}
                        </div>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UserCreateModal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          refetch()
        }}
      />
      <UserUpdateModal
        show={modalEditShow}
        onHide={() => {
          setModalEditShow(false)
          refetch()
        }}
        selectedUser={selectedUser}
      />
      <UserViewModal
        show={modalDetailShow}
        onHide={() => {
          setModalDetailShow(false)
        }}
        selectedUser={selectedUser}
      />
    </>
  )
}

export default AdminPage
