import { AxiosResponse } from 'axios'
import { ITransaction, IUser } from '../../interfaces'
import axiosInstance from '../axios'
import {
  IAssetBalanceResponse,
  IAssetResponse,
  IContractResponse,
  IGetTransactionParams,
  IPostAssetBody,
  IPostContractAsset,
  IPostCurrencyAsset,
  IPostLoanAsset,
  ITransactionResponse,
} from './assetsManagmentType'
import { IResponseTemplate } from '../types'

export const getAssetById = (id: string): Promise<AxiosResponse<IAssetResponse>> =>
  axiosInstance.get(`/assets/${id}`)
export const postNewAsset = (data: IPostAssetBody): Promise<AxiosResponse<IAssetResponse>> =>
  axiosInstance.post('/assets', data)
export const putNewAsset = (
  id: string,
  data: IPostAssetBody,
): Promise<AxiosResponse<IAssetResponse>> => axiosInstance.put(`/assets/${id}`, data)
export const postApproveAsset = (
  id: string,
  data: {
    comment?: string
  },
): Promise<AxiosResponse<IAssetResponse>> => axiosInstance.post(`/assets/${id}/approve`, data)
export const postRejectAsset = (
  id: string,
  data: {
    comment?: string
  },
): Promise<AxiosResponse<IAssetResponse>> => axiosInstance.post(`/assets/${id}/reject`, data)

// contracts

export const postNewContract = (
  data: IPostContractAsset,
): Promise<AxiosResponse<IContractResponse>> => axiosInstance.post('/contracts', data)

export const putContract = (
  id: string,
  data: IPostContractAsset,
): Promise<AxiosResponse<IContractResponse>> => axiosInstance.put(`/contracts/${id}`, data)

export const postApproveContract = (
  id: string,
  data: {
    comment?: string
  },
): Promise<AxiosResponse<IAssetResponse>> => axiosInstance.post(`/contracts/${id}/approve`, data)
export const postRejectContract = (
  id: string,
  data: {
    comment?: string
  },
): Promise<AxiosResponse<IAssetResponse>> => axiosInstance.post(`/contracts/${id}/reject`, data)

// Transactions
export const getTransactions = (
  params: IGetTransactionParams,
): Promise<AxiosResponse<IResponseTemplate<ITransactionResponse[]>>> =>
  axiosInstance.get('/transactions', {
    params,
  })

export const getTransactionById = (id: string): Promise<AxiosResponse<ITransactionResponse>> =>
  axiosInstance.get(`/transactions/${id}`)

export const putTransaction = (
  id: string,
  body: { amount?: string; assetAmount?: string },
): Promise<AxiosResponse<ITransactionResponse>> => axiosInstance.put(`/transactions/${id}`, body)

export const setContractPendingDistribution = (
  id: string,
): Promise<AxiosResponse<ITransactionResponse>> =>
  axiosInstance.post(`/contracts/${id}/set-pending-distribution`)

export const postApproveDistributionContract = (
  id: string,
  data: {
    comment?: string
  },
): Promise<AxiosResponse<IAssetResponse>> =>
  axiosInstance.post(`/contracts/${id}/approve-distribution`, data)
export const postRejectDistributionContract = (
  id: string,
  data: {
    comment?: string
  },
): Promise<AxiosResponse<IAssetResponse>> =>
  axiosInstance.post(`/contracts/${id}/reject-distribution`, data)
export const postResubmitDistributionContract = (
  id: string,
): Promise<AxiosResponse<IAssetResponse>> => axiosInstance.post(`/contracts/${id}/re-distribute`)

// balances
export const getCurrencyBalance = (id: string): Promise<AxiosResponse<IAssetBalanceResponse>> =>
  axiosInstance.get(`/assets/${id}/currency-balance`)
// balances
export const getOrganizationBalance = (id: string): Promise<AxiosResponse<IAssetBalanceResponse>> =>
  axiosInstance.get(`/assets/${id}/organization-balance`)
