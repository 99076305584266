import React from 'react'
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap'
import { IAssetResponse } from '../../api/assets_managment/assetsManagmentType'
import { formatter } from '../../helper'

const AssetContractHeadCard: React.FC<{
  assetdata?: IAssetResponse
  currencybalance?: number
  organizationBalance?: number
}> = ({ assetdata, currencybalance, organizationBalance }) => {
  return (
    <Card border='light' className='card-shadow'>
      <Card.Body>
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item href='/asset-management'>Asset Management</Breadcrumb.Item>
              <Breadcrumb.Item active href='#'>
                Asset Contracts
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{assetdata?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Row>
              <Col className='d-flex align-items-center'>
                <h6 className='mb-3 fw-bold'>Asset Detail</h6>
              </Col>
            </Row>

            <table className='w-100'>
              <tbody>
                <tr>
                  <td className='fw-bolder'>Name</td>
                  <td>: {assetdata?.name}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Symbol</td>
                  <td>: {assetdata?.symbol}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Type</td>
                  <td>: {assetdata?.type}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Price</td>
                  <td>
                    : {assetdata?.price && formatter(Number(assetdata.price))} (
                    {assetdata?.currencyAsset?.symbol})
                  </td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Quantity</td>
                  <td>
                    :{' '}
                    {assetdata?.maxSupply &&
                      new Intl.NumberFormat('us-EN').format(Number(assetdata.maxSupply))}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Custodian</td>
                  <td>: {assetdata?.custodian.fullname}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Organization Asset Balance</td>
                  <td>
                    :{' '}
                    {(organizationBalance !== null &&
                      organizationBalance !== undefined &&
                      formatter(organizationBalance)) ||
                      '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col>
            <Row>
              <Col className='d-flex align-items-center'>
                <h6 className='mb-3 fw-bold'>Currency Detail</h6>
              </Col>
            </Row>
            <table className='w-100'>
              <tbody>
                <tr>
                  <td className='fw-bolder'>Name</td>
                  <td>: {assetdata?.currencyAsset?.name}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Symbol</td>
                  <td>: {assetdata?.currencyAsset?.symbol}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Decimal Places</td>
                  <td>: {assetdata?.currencyAsset?.decimalPlaces}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Custodian</td>
                  <td>: {assetdata?.currencyAsset?.custodian?.fullname || '-'}</td>
                </tr>
                <tr>
                  <td className='fw-bolder'>Organization Currency Balance</td>
                  <td>
                    :{' '}
                    {(currencybalance !== null &&
                      currencybalance !== undefined &&
                      formatter(currencybalance)) ||
                      '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default AssetContractHeadCard
