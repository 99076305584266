export interface IUserResponse {
  bankCurrency: string
  bankName: string
  bankNumber: string
  bankSwift: string
  createdAt: string
  email: string
  fullname: string
  id: string
  organizationId: string
  type: string
  updatedAt: string
  externalId: string
  username: string
  walletAddress: string
  externalWalletAddress?: string
}

export interface IPostUserBody {
  email: string
  fullname: string
  type: string
  bankCurrency: string
  bankName: string
  bankNumber: string
  bankSwift: string
  externalWalletAddress?: string
}

export enum UserType {
  CORPORATE = 'CORPORATE',
  INDIVIDUAL = 'INDIVIDUAL',
}

export const UserTypeOptions = [
  {
    label: 'CORPORATE',
    value: UserType.CORPORATE,
  },
  {
    label: 'INDIVIDUAL',
    value: UserType.INDIVIDUAL,
  },
]
