import React from 'react'
import { ITransactionTable, TRANSACTIONS_PAGE_SIZE } from '../types'
import { Table } from 'react-bootstrap'
import AmountInput from './AmountInput'
import ReactPaginate from 'react-paginate'

const TransactionsTable: React.FC<ITransactionTable> = ({
  transactiondata,
  isLoading,
  handleGetTransaction,
  setIsLoading,
  page,
  handlePageChange,
  totalpage,
}) => {
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>User</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactiondata.map((transaction, index) => (
            <tr key={index}>
              <td className='w-50'>{transaction.user.fullname}</td>
              <td>
                <AmountInput
                  setIsLoading={(val: boolean) => setIsLoading(val)}
                  isLoading={isLoading}
                  transaction={transaction}
                  refetch={() => page && handleGetTransaction(page * TRANSACTIONS_PAGE_SIZE)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='d-flex justify-content-center align-items-center'>
        <ReactPaginate
          breakLabel='...'
          nextLabel='>'
          onPageChange={(prop) => handlePageChange && handlePageChange(prop.selected)}
          pageRangeDisplayed={3}
          pageCount={
            (transactiondata && totalpage && Math.ceil(totalpage / TRANSACTIONS_PAGE_SIZE)) || 0
          }
          previousLabel='<'
          renderOnZeroPageCount={null}
          className='pagination my-2'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          activeClassName='active'
        />
      </div>
    </>
  )
}

export default TransactionsTable
