import React, { ChangeEvent } from 'react'
import {
  ContractTypeEnum,
  ContractTypeOption,
  IAssetResponse,
  IContractResponse,
  IPostContractAsset,
  PostAssetStatus,
  SWAP_TYPE,
} from '../../api/assets_managment/assetsManagmentType'
import usePagination from '../../hooks/usePagination'
import { IUserResponse } from '../../api/user/userType'
import { useForm } from 'react-hook-form'
import { assetContractSchema, roleoptions } from './types'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  postApproveContract,
  postNewContract,
  postRejectContract,
  putContract,
} from '../../api/assets_managment/assetsManagement'
import { currencyStringToNumber, handleAxiosError } from '../../helper'
import { Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap'
import Select, { MultiValue, SingleValue } from 'react-select'
import UserSelect from '../../components/Select/UserSelect'
import CurrencyInput from '../../components/Input/CurrencyInput'
import checked from '../../assets/images/checked.png'
import moment from 'moment'
import DatePicker from 'react-date-picker'

export type ContractModalType = 'CREATE' | 'UPDATE' | 'APPROVAL' | 'VIEW'

const ContractCreateModal = (props: {
  show: boolean
  onHide: () => void
  assetdata?: IAssetResponse
  selectedcontract?: IContractResponse
  type: ContractModalType
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)
  const [comment, setComment] = React.useState<string>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onHide, show, assetdata, selectedcontract, type } = props

  const IS_NOT_VIEW_MODAL = type !== 'APPROVAL' && type !== 'VIEW'
  const { data: users } = usePagination<IUserResponse>({
    queryKey: ['getUsersWallet'],
    url: '/users',
    handlePageParams: false,
    enable: IS_NOT_VIEW_MODAL,
    pageSize: 99,
    mapDataFn: (users) =>
      users.map((user: IUserResponse) => ({
        ...user,
        value: user.id,
        label: user.fullname,
      })),
    queryParams: {
      allowOrganizationWallet: 'true',
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    getValues,
  } = useForm<IPostContractAsset>({
    resolver: yupResolver(assetContractSchema),
  })

  React.useEffect(() => {
    if (props.assetdata && props.show === true) {
      setValue('assetId', props.assetdata.id)
    }
  }, [show])

  React.useEffect(() => {
    if (selectedcontract) {
      setValue('type', selectedcontract.type)
      setValue('amount', selectedcontract.amount)
      setValue('distributionDate', moment(selectedcontract.distributionDate).format('YYYY-MM-DD'))
      setValue('condition', selectedcontract.condition)
      setValue('cashflowDetail', selectedcontract.cashflowDetail)
      selectedcontract.users &&
        selectedcontract.users.length > 0 &&
        setValue(
          'userIds',
          selectedcontract.users.map((user) => ({ label: user.fullname, value: user.id })),
        )

      selectedcontract.assetAmount && setValue('assetAmount', selectedcontract.assetAmount)
      selectedcontract.buyerUser &&
        setValue('buyerUserId', {
          label: selectedcontract.buyerUser.fullname,
          value: selectedcontract.buyerUser.id,
        })
      selectedcontract.sellerUser &&
        setValue('sellerUserId', {
          label: selectedcontract.sellerUser.fullname,
          value: selectedcontract.sellerUser.id,
        })
      setComment(selectedcontract?.comment)
    }
  }, [selectedcontract])

  const onSubmit = async (data: IPostContractAsset) => {
    setIsLoading(true)

    try {
      const _userIds =
        (!SWAP_TYPE(data.type) && data.userIds && data.userIds.map((user: any) => user.value)) ||
        undefined

      const response = await postNewContract({
        ...data,
        userIds: _userIds,
        buyerUserId: SWAP_TYPE(data.type) ? data.buyerUserId : undefined,
        sellerUserId: SWAP_TYPE(data.type) ? data.sellerUserId : undefined,
      })
      if (response.status === 201) {
        setIsComplete(true)
        reset()
      }
    } catch (error) {
      handleAxiosError(error)
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  // options
  const sellerOptions =
    (watch('buyerUserId') && users.filter((val) => val.id !== watch('buyerUserId'))) || users
  const buyerOptions =
    (watch('sellerUserId') && users.filter((val) => val.id !== watch('sellerUserId'))) || users

  const PAYER_CASE =
    watch('type') === ContractTypeEnum.ASSET_SUBSCRIPTION ||
    watch('type') === ContractTypeEnum.LOAN_AUTODEBIT
  const PAYEE_CASE =
    watch('type') === ContractTypeEnum.LOAN_DISBURSEMENT ||
    watch('type') === ContractTypeEnum.YIELD_DISBURSEMENT

  const handleSubmitApproval = async (type: 'APPROVE' | 'REJECT') => {
    if (!selectedcontract) return
    setIsLoading(true)
    try {
      if (type === 'APPROVE') {
        await postApproveContract(selectedcontract.id, { comment: comment })
      } else {
        await postRejectContract(selectedcontract.id, { comment: comment })
      }
      setIsComplete(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmitUpdate = async (data: IPostContractAsset) => {
    setIsLoading(true)

    const { assetId, ...newData } = data

    try {
      const _userIds =
        (!SWAP_TYPE(newData.type) &&
          newData.userIds &&
          newData.userIds.map((user: any) => user.value)) ||
        undefined

      if (!selectedcontract?.id) return

      const response = await putContract(selectedcontract.id, {
        ...newData,
        userIds: _userIds,
        buyerUserId: SWAP_TYPE(newData.type) ? newData.buyerUserId.value : undefined,
        sellerUserId: SWAP_TYPE(newData.type) ? newData.sellerUserId.value : undefined,
      })

      setIsComplete(true)
      reset()
    } catch (error) {
      handleAxiosError(error)
      // ..
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      {...props}
      dialogClassName='modal-90w'
      size='lg'
      aria-labelledby='user-modal'
      centered
      onHide={() => {
        setIsComplete(false)
        reset()
        onHide()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id='deposit-modal'>
          <h5 className='m-0 fw-bold'>
            {type === 'CREATE'
              ? 'Create'
              : type === 'UPDATE'
              ? 'Update'
              : type === 'APPROVAL'
              ? 'Aprrove'
              : 'View'}{' '}
            Contract
          </h5>
        </Modal.Title>
      </Modal.Header>
      {isComplete ? (
        <>
          <Modal.Body>
            <div className='d-flex justify-content-center align-items-center flex-column text-center'>
              <Image
                src={checked}
                alt='checked'
                fluid
                style={{
                  maxWidth: '100px',
                }}
                className='mb-4'
              />
              <h6 className='mb-4'>
                Contract {type === 'CREATE' ? 'created' : 'Updated'} successfully
              </h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
                setIsComplete(false)
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form
          onSubmit={handleSubmit(
            type === 'CREATE' ? onSubmit : type === 'UPDATE' ? handleSubmitUpdate : () => null,
          )}
        >
          <Modal.Body>
            {isLoading && (
              <div className='overlay-loader'>
                <Spinner animation='border' variant='primary' />
              </div>
            )}

            <Form.Group className='mb-3' controlId='contractType'>
              <Form.Label>
                Contract Type<sup>*</sup>
              </Form.Label>
              <Select
                options={ContractTypeOption}
                value={ContractTypeOption.find((type) => type.value === watch('type'))}
                placeholder='Select contract type'
                isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                onChange={(option: SingleValue<typeof ContractTypeOption> | any) => {
                  setValue('type', option?.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.type?.message?.toString()}</Form.Text>
            </Form.Group>

            {!SWAP_TYPE(watch('type')) && (
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='userIds'>
                    <Form.Label>
                      Users<sup>*</sup>
                    </Form.Label>
                    <UserSelect
                      isSearchable={true}
                      options={users}
                      isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                      isMulti
                      name='userIds'
                      value={watch('userIds')}
                      onChange={(option: MultiValue<IUserResponse> | any) => {
                        setValue('userIds', option)
                      }}
                    />
                    <Form.Text className='text-danger'>
                      {errors.userIds?.message?.toString()}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3' controlId='userrole'>
                    <Form.Label>User Role</Form.Label>
                    <Select
                      isSearchable={false}
                      options={roleoptions}
                      isDisabled={true}
                      name='userrole'
                      placeholder='User role'
                      value={PAYER_CASE ? roleoptions[0] : PAYEE_CASE ? roleoptions[1] : null}
                      onChange={(option: MultiValue<IUserResponse> | any) => {
                        setValue('userIds', option)
                      }}
                    />
                    <Form.Text className='text-danger'>
                      {errors.userIds?.message?.toString()}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {SWAP_TYPE(watch('type')) && (
              <>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='userIds'>
                      <Form.Label>
                        User (Payer)<sup>*</sup>
                      </Form.Label>
                      <UserSelect
                        isSearchable={true}
                        options={buyerOptions}
                        isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                        name='buyerId'
                        defaultValue={watch('buyerUserId')}
                        value={
                          watch('buyerUserId') &&
                          buyerOptions.find((option: any) => option.id === watch('buyerUserId'))
                        }
                        onChange={(option: SingleValue<IUserResponse> | any) => {
                          setValue('buyerUserId', option)
                        }}
                      />
                      <Form.Text className='text-danger'>
                        {errors.buyerUserId?.message?.toString()}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='quantity'>
                      <Form.Label>
                        {assetdata?.symbol} Quantity (+ve)<sup>*</sup>
                      </Form.Label>
                      <CurrencyInput
                        className='form-control'
                        disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                        value={watch('assetAmount') && Number(watch('assetAmount'))}
                        type='text'
                        placeholder='Enter quantity'
                        {...register('assetAmount', { required: true })}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setValue('assetAmount', currencyStringToNumber(e.target.value))
                        }}
                      />

                      <Form.Text className='text-danger'>
                        {errors.assetAmount?.message?.toString()}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='assetAmount'>
                      <Form.Label>
                        {assetdata?.currencyAsset?.symbol} Amount (-ve)<sup>*</sup>
                      </Form.Label>
                      <CurrencyInput
                        className='form-control'
                        disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                        value={watch('amount') && Number(watch('amount'))}
                        type='text'
                        placeholder='Enter quantity'
                        {...register('amount', { required: true })}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setValue('amount', currencyStringToNumber(e.target.value))
                        }}
                      />

                      <Form.Text className='text-danger'>
                        {errors.amount?.message?.toString()}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className='mb-3' controlId='userIds'>
                      <Form.Label>
                        User (Payee)<sup>*</sup>
                      </Form.Label>
                      <UserSelect
                        isSearchable={true}
                        options={sellerOptions}
                        isDisabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                        name='sellerId'
                        defaultValue={watch('sellerUserId')}
                        value={
                          watch('sellerUserId') &&
                          sellerOptions.find((option: any) => option.id === watch('sellerUserId'))
                        }
                        onChange={(option: SingleValue<IUserResponse> | any) => {
                          setValue('sellerUserId', option)
                        }}
                      />
                      <Form.Text className='text-danger'>
                        {errors.sellerUserId?.message?.toString()}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='amount'>
                      <Form.Label>{assetdata?.symbol} Quantity (-ve) </Form.Label>
                      <CurrencyInput
                        className='form-control'
                        disabled={true}
                        defaultValue=''
                        type='text'
                        placeholder='Enter quantity'
                        value={watch('assetAmount') && Number(watch('assetAmount'))}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-3' controlId='amount'>
                      <Form.Label>{assetdata?.currencyAsset?.symbol} Amount (+ve) </Form.Label>
                      <CurrencyInput
                        className='form-control'
                        disabled={true}
                        defaultValue=''
                        type='text'
                        placeholder='Enter quantity'
                        value={watch('amount') && Number(watch('amount'))}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            {!SWAP_TYPE(watch('type')) && (
              <Form.Group className='mb-3' controlId='amount'>
                <Form.Label>
                  Amount {PAYER_CASE ? '(-ve)' : '(+ve)'}
                  <sup>*</sup>
                </Form.Label>
                <CurrencyInput
                  className='form-control'
                  disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                  value={watch('amount') && Number(watch('amount'))}
                  type='text'
                  placeholder='Enter amount'
                  {...register('amount', { required: true })}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('amount', currencyStringToNumber(e.target.value))
                  }}
                />

                <Form.Text className='text-danger'>{errors.amount?.message?.toString()}</Form.Text>
              </Form.Group>
            )}

            {SWAP_TYPE(watch('type')) && (
              <Form.Group className='mb-3' controlId='amount'>
                <Form.Label>Price ({assetdata?.currencyAsset?.symbol}) </Form.Label>
                <CurrencyInput
                  className='form-control'
                  disabled={true}
                  type='text'
                  placeholder='price'
                  value={
                    watch('assetAmount') &&
                    watch('amount') &&
                    Number(watch('amount')) / Number(watch('assetAmount'))
                  }
                />

                {/* <Form.Text className='text-danger'>{errors.amount?.message?.toString()}</Form.Text> */}
              </Form.Group>
            )}

            <Form.Group className='mb-3' controlId='detail'>
              <Form.Label>
                Distribution Date (mm-dd-yyyy)<sup>*</sup>
              </Form.Label>
              <DatePicker
                format='M-dd-yyyy'
                disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                className={'form-control'}
                value={
                  watch('distributionDate') &&
                  moment(watch('distributionDate')).format('MM-DD-YYYY')
                }
                onChange={(val) => {
                  const _val = val as Date | null
                  setValue(
                    'distributionDate',
                    (_val && moment(_val).format('YYYY-MM-DD')) || undefined,
                  )
                }}
              />

              <Form.Text className='text-danger'>{errors.condition?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='condition'>
              <Form.Label>
                Condition<sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                defaultValue=''
                type='text'
                as='textarea'
                placeholder='Enter condition'
                {...register('condition', { required: true })}
                onChange={(e) => {
                  setValue('condition', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.condition?.message?.toString()}</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='detail'>
              <Form.Label>
                Contract Detail<sup>*</sup>
              </Form.Label>
              <Form.Control
                disabled={IS_NOT_VIEW_MODAL ? isLoading : true}
                defaultValue=''
                type='text'
                as='textarea'
                placeholder='Enter detail'
                {...register('cashflowDetail', { required: true })}
                onChange={(e) => {
                  setValue('cashflowDetail', e.target.value)
                }}
              />
              <Form.Text className='text-danger'>{errors.condition?.message?.toString()}</Form.Text>
            </Form.Group>

            {(type === 'APPROVAL' || type === 'VIEW') && (
              <Form.Group className='mb-3' controlId='username'>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  disabled={type === 'VIEW' ? true : isLoading}
                  as='textarea'
                  type='text'
                  placeholder='Enter comment'
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
                />
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant='light'
              onClick={() => {
                reset()
                onHide()
              }}
            >
              Cancel
            </Button>
            {(type === 'CREATE' || type === 'UPDATE') && (
              <>
                <Button
                  variant='primary'
                  type='submit'
                  onClick={() => {
                    setValue('status', PostAssetStatus.DRAFT)
                  }}
                  disabled={isLoading}
                >
                  Save as Draft
                </Button>
                <Button
                  variant='primary'
                  onClick={() => {
                    setValue('status', PostAssetStatus.PENDING)
                  }}
                  type='submit'
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </>
            )}

            {type === 'APPROVAL' && (
              <>
                <Button
                  variant='primary'
                  type='button'
                  onClick={() => handleSubmitApproval('REJECT')}
                  disabled={isLoading}
                >
                  Reject
                </Button>
                <Button
                  variant='primary'
                  onClick={() => handleSubmitApproval('APPROVE')}
                  type='button'
                  disabled={isLoading}
                >
                  Approve
                </Button>
              </>
            )}
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  )
}

export default ContractCreateModal
