import { UserProfile } from 'oidc-client-ts'
import { IAdmin } from '../api/admin/adminType'

const LOCALSTORAGE_KEYNAME = 'data'

export interface PersistentState {
  user?: UserProfile
  token?: string
  refreshToken?: string
}

export const persistentState: PersistentState = {}

export const getLocalStorage = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storage = window.localStorage.getItem(LOCALSTORAGE_KEYNAME)
    if (storage) {
      try {
        return JSON.parse(storage) as PersistentState
      } catch (err) {
        return persistentState
      }
    } else return persistentState
  }
  return null
}

export const setToLocalStorage = (state: Partial<PersistentState>) => {
  const newStore: PersistentState = {
    ...getLocalStorage(),
    ...state,
  }
  // encrypt localStorage object to hash
  const encryptedData = JSON.stringify(newStore)
  localStorage.setItem(LOCALSTORAGE_KEYNAME, encryptedData.toString())
}
